import React, { Component } from 'react';
// import { RichText } from '../richTextOptions';
import { Image } from '../BlockLibrary/Common/Image';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';

class CustomerReviewList extends Component {
     constructor(props) {
          super(props);

          this.fullReviewText = props.reviews.mainText ? 
               (props.reviews.isPreview ? props.reviews.mainText.content[0].content[0].value : props.reviews.mainText.json.content[0].content[0].value) : '';

          this.state = {
               reviewText: this.fullReviewText.substr(0, 150),
               isShowMore: false
          }
     }

     showMore = (e) => {
          // Prevent from clicking button which causes a redirect
          e.preventDefault();
          this.setState({ reviewText: this.fullReviewText, isShowMore: true });
     }

     showLess = (e) => {
          e.preventDefault();
          this.setState({ reviewText: this.fullReviewText.substr(0, 150), isShowMore: false });
     }

     render() {
          const { reviews } = this.props;

          return (
               // Customer Reviews on pet-insurance-reviews page
               <>
                    <div className="img">
                         {
                              reviews.petImage ? (
                                   <Image image={reviews.petImage} />
                              )
                                   : (<img src="../images/no-dog.png" alt='no-dog' />)
                         }
                    </div>
                    {reviews.reviewHeadline && (<h3 className="title">{reviews.reviewHeadline}</h3>)}
                    <div className="info">
                         {reviews.ownersName && (<p>{reviews.ownersName}</p>)}
                         {reviews.petsName && (<p><strong>{reviews.petsName}</strong></p>)}
                    </div>
                    {reviews.mainText && (
                         <div className="review-text">
                              {
                                   (this.fullReviewText.length < 150) ? (<p>{this.state.reviewText}</p>) :
                                        (
                                             this.state.isShowMore ? (
                                                  <p>
                                                       {this.state.reviewText}
                                                       <a href="" className="show-less" aria-label="Show less" onClick={this.showLess}> Show less</a>
                                                  </p>
                                             ) : (
                                                       <p>
                                                            {this.state.reviewText + '...'}
                                                            <a href="" className="show-more" aria-label="Show more" onClick={this.showMore}> Show more</a>
                                                       </p>
                                                  )
                                        )
                              }
                         </div>
                    )}
                    {
                         reviews.wouldYouRecommendPetInsurance && (
                              <div className="recommend">
                                   <p>Would you recommend pet insurance:
          			               <strong> {reviews.wouldYouRecommendPetInsurance}</strong>
                                   </p>
                              </div>)
                    }
               </>
          )
     }
}

const CustomerReviews = ({ reviews }) => {
     const ratingContent = [];

     for (let i = 0; i < 5; i++) {
          if (reviews.starRating > i) {
               let linkAlt = "star-ratting-" + (i + 1)
               ratingContent.push(
                    <img
                         src="/images/cat-insurance/star-ratting.png"
                         alt={linkAlt}
                         key={i}
                    />
               )
          } else {
               let linkAltNot = "star-not-ratting-" + (i + 1)
               ratingContent.push(
                    <img
                         src="/images/cat-insurance/star-not-ratting.png"
                         alt={linkAltNot}
                         key={i}
                    />
               )
          }
     }

     var date = new Date(reviews.reviewDate);
     const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"];
     var reviewDate = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

     return (
          // Customer Reviews on cat-insurance or dog-insurance page
          <>
               <div className="avatar-cat">
                    {reviews.petImage && (
                         <Image image={reviews.petImage} />
                    )}
               </div>
               <div className="detail-review">
                    <div className="ratting">
                         <div className="star-rattings">
                              {reviews.starRating && (
                                   <>
                                        {ratingContent}
                                        <span className="score">{reviews.starRating}/5</span>
                                   </>
                              )}
                         </div>
                    </div>
                    {reviews.reviewDate && (<div className="date">{reviewDate}</div>)}
                    {reviews.reviewHeadline && <a href="/pet-insurance-reviews/" aria-label={reviews.reviewHeadline}>{reviews.reviewHeadline}</a>}
                    <p>
                         <strong>{reviews.ownersName}<br /> Pet Parent of {reviews.petsName}<br />From</strong>{' '}
                         {reviews.customerLocation && reviews.customerLocation}
                    </p>
               </div>
          </>
     )
}

const BlockCustomerReviews = (reviews) => {
     return (
          <>
               {
                    reviews.template == 'list' ? (
                         // Customer Reviews on pet-insurance-reviews page
                         <CustomerReviewList reviews={reviews} />
                    ) : (
                              // Customer Reviews on cat-insurance or dog-insurance page
                              <CustomerReviews reviews={reviews} />
                         )
               }
          </>
     )
}

const mapStateToProps= (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

BlockCustomerReviews.propTypes = {
     isPreview: PropTypes.bool,
}

const ContentfulBlockCustomerReviews = connect(mapStateToProps)(BlockCustomerReviews);

export { ContentfulBlockCustomerReviews }
