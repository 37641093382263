import React from 'react';
import Block from './block';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getIsPreviewStatus } from '../state/app';
import { RichText } from './richTextOptions';
import { Image } from './BlockLibrary/Common/Image';

const DefaultFooter = ({ footer, isPreview, specificPhone }) => {
     if (footer.menus) {
          const textBesidePhone = footer.phoneNumberText.substr(0, footer.phoneNumberText.length - 14);
          const phoneNumber = specificPhone != null ? specificPhone : footer.phoneNumberText.substr(footer.phoneNumberText.length - 14);

          const convertToSlug = (Text) => {
               return Text
                    .toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '')
                    ;
          }

          return (
               <footer>
                    <div className="container">
                         <div className="footer-container">
                              <div className="row">
                                   {
                                        isPreview != true ? (
                                             footer.menus.map((menu, i) => {
                                                  return (
                                                       <nav className="footer-col col-lg-2 is-desktop" key={i} aria-label={menu.dropdownsOrLinks[0].linkText}>
                                                            <h4>{menu.dropdownsOrLinks[0].linkText}</h4>
                                                            <ul id={"footer-nav-" + convertToSlug(menu.dropdownsOrLinks[0].linkText)}>
                                                                 {
                                                                      menu.dropdownsOrLinks.map((node, i) => {
                                                                           return ((i != 0) && (<li key={i}><Block block={node} /></li>))
                                                                      })
                                                                 }
                                                            </ul>
                                                       </nav>
                                                  )
                                             })
                                        ) : (
                                                  footer.menus.map((menu, i) => {
                                                       return (
                                                            <nav className="footer-col col-lg-2 is-desktop" key={i} aria-label={menu.fields.dropdownsOrLinks[0].fields.linkText}>
                                                                 <h4>{menu.fields.dropdownsOrLinks[0].fields.linkText}</h4>
                                                                 <ul id={"footer-nav-" + convertToSlug(menu.fields.dropdownsOrLinks[0].fields.linkText)}>
                                                                      {
                                                                           menu.fields.dropdownsOrLinks.map((node, i) => {
                                                                                return ((i != 0) && (<li key={i}><Block block={node} /></li>))
                                                                           })
                                                                      }
                                                                 </ul>
                                                            </nav>
                                                       )
                                                  })
                                             )
                                   }
                                   <div className="col-12 is-mobile">
                                        <a href={`tel:${phoneNumber}`} className="call-button">
                                             <img src="/images/phone-icon.png" alt="phone-icon" />
                                                       Tap to Call
                                                  </a>
                                   </div>
                                   <nav className="footer-col-mobile col-6 text-left is-mobile" aria-label="footer nav mobile left">
                                        <ul id="footer-nav-mobile-left">
                                             <li><a aria-label="Link to Pet Insurance" href="/pet-insurance/">Pet Insurance</a></li>
                                             <li><a aria-label="Link to My Account" href="https://my.petinsurance.com/">My Account</a></li>
                                             <li><a aria-label="Link to Compare Us" href="/comparison/">Compare Us</a></li>
                                             <li><a aria-label="Link to About Us" href="/we-invented/">About Us</a></li>
                                             <li><a aria-label="Link to Contact Us" href="/contact/">Contact Us</a></li>
                                        </ul>
                                   </nav>
                                   <nav className="footer-col-mobile col-6 text-right is-mobile" aria-label="footer nav mobile right">
                                        <ul id="footer-nav-mobile-right">
                                             <li><a aria-label="Link to Nationwide.com" href="https://www.nationwide.com/" target="_blank">Nationwide.com</a></li>
                                             <li><a aria-label="Link to Banking" href="https://www.nationwide.com/online-banking.jsp" target="_blank">Banking</a>
                                             </li>
                                             <li><a aria-label="Link to Investing" href="https://www.nationwide.com/investments-and-retirement.jsp"
                                                  target="_blank">Investing</a></li>
                                             <li><a aria-label="Link to Careers" href="https://www.nationwide.com/about-us/careers.jsp" target="_blank">Careers</a>
                                             </li>
                                             <li><a aria-label="Link to Privacy Policy" href="https://www.nationwide.com/privacy-security.jsp">Privacy Policy</a></li>
                                        </ul>
                                   </nav>
                                   <div className="footer-info col-lg-6">
                                        <div className="row">
                                             <div className="col-xl-7 col-lg-8 offset-xl-5 offset-lg-4">
                                                  <div className="footer-cta is-desktop">
                                                       {
                                                            isPreview != true ? (
                                                                 footer.footerCtaMenu.dropdownsOrLinks.map((node, i) => {
                                                                      return (<Block block={node} key={i} />)
                                                                 })
                                                            ) : (
                                                                      footer.footerCtaMenu.fields.dropdownsOrLinks.map((node, i) => {
                                                                           return (<Block block={node} key={i} />)
                                                                      })
                                                                 )
                                                       }
                                                  </div>
                                                  <nav className="social-icons" aria-label="footer social icons">
                                                       <ul className="list-inline" id="footer-list-inline-icons">
                                                            {footer.socialIcons.map((node, i) => {
                                                                 return (<li className="list-inline-item" key={i}><Block block={node} /></li>)
                                                            })}
                                                       </ul>
                                                  </nav>
                                                  {
                                                       phoneNumber && (
                                                            <div className="phone-number is-desktop">
                                                                 {textBesidePhone}<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                                                            </div>
                                                       )
                                                  }
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="footer-disclaimer">
                              {footer.termsText && <RichText data={footer.termsText}></RichText>}
                         </div>
                    </div>
               </footer>
          )
     } else {
          return (
               <footer>
                    <div className="container">
                         <div className="footer-disclaimer">
                              {footer.termsText && <RichText data={footer.termsText}></RichText>}
                         </div>
                    </div>
               </footer>
          )
     }
}

const Wacky = ({ footer, isPreview }) => {
     return (
          <footer className="footer-wacky">
               <div className="container">
                    <div className="footer-container">
                         <div className="row">
                              {
                                   footer.menus && (
                                        isPreview != true ? (
                                             footer.menus.map((menu, i) => {
                                                  return (
                                                       <nav className={`footer-col col-6 col-md-3 col-lg-2 footer-col-${i + 1}`} key={i} aria-label="wacky footer menu">
                                                            <ul className="footer-">
                                                                 {
                                                                      menu.dropdownsOrLinks.map((node, i) => {
                                                                           return (<li key={i}><Block block={node} /></li>)
                                                                      })
                                                                 }
                                                            </ul>
                                                       </nav>
                                                  )
                                             })
                                        ) : (
                                                  footer.menus.map((menu, i) => {
                                                       return (
                                                            <nav className="footer-col col-6 col-md-3 col-lg-2" key={i} aria-label="wacky footer menu">
                                                                 <ul>
                                                                      {
                                                                           menu.fields.dropdownsOrLinks.map((node, i) => {
                                                                                return (<li key={i}><Block block={node} /></li>)
                                                                           })
                                                                      }
                                                                 </ul>
                                                            </nav>
                                                       )
                                                  })
                                             )
                                   )
                              }
                              <div className="footer-info col-md-6 col-lg-8">
                                   <nav className="social-icons" aria-label="footer social icons">
                                        <ul className="list-inline" id="footer-list-inline-icons">
                                             {footer.socialIcons.map((node, i) => {
                                                  return (<li className="list-inline-item" key={i}><Block block={node} /></li>)
                                             })}
                                        </ul>
                                   </nav>
                              </div>
                         </div>
                    </div>
                    <div className="footer-disclaimer">
                         {
                              footer.termsText && <RichText data={footer.termsText}></RichText>
                         }
                    </div>
               </div>
          </footer>
     )
}

const CurePetCancer = ({ footer, isPreview }) => {
     return (
          <footer className="footer-cpc">
               <div className="container">
                    <div className="deskFooter">
                         <div className="row">
                              {footer.menus && footer.menus.map((menu, i) => {
                                   return (
                                        <div className={`col-md-4 ${(i + 1) != footer.menus.length ? 'footer-container' : 'footer-end-col'}`} key={i}>
                                             <h2>{menu.contentName}</h2>
                                             {
                                                  menu.dropdownsOrLinks && (
                                                       <ul>
                                                            {
                                                                 isPreview ? (
                                                                      menu.fields.dropdownsOrLinks.map((item, index) => (
                                                                           <li key={index}><Block block={item} /></li>
                                                                      ))
                                                                 ) : (
                                                                           menu.dropdownsOrLinks.map((item, index) => (
                                                                                <li key={index}><Block block={item} /></li>
                                                                           ))
                                                                      )
                                                            }
                                                       </ul>
                                                  )
                                             }
                                        </div>
                                   )
                              })}
                         </div>
                    </div>
                    <div className="divider"></div>
                    <div className="social-icons">
                         {
                              footer.socialIcons.map((socialIcon, i) => (
                                   <Block block={socialIcon} key={i} />
                              ))
                         }
                    </div>
                    {footer.termsText && (
                         <div className="terms-text">
                              <RichText data={footer.termsText}></RichText>
                         </div>
                    )}
               </div>
          </footer>
     )
}

const Footer = ({ footer, isPreview, specificPhone }) => {
     if (footer == null) { return <></> }
     if (isPreview == true) {
          footer = footer.fields;
     }

     let content = '';

     switch (footer.layout) {
          case 'Wacky':
               content = <Wacky footer={footer} isPreview={isPreview}></Wacky>
               break;
          case 'Cure Pet Cancer':
               content = <CurePetCancer footer={footer} isPreview={isPreview}></CurePetCancer>
               break;
          default:
               content = <DefaultFooter footer={footer} isPreview={isPreview} specificPhone={specificPhone}></DefaultFooter>
     }

     return (
          content
     )
}

Footer.propTypes = {
     isPreview: PropTypes.bool,
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

export default connect(mapStateToProps)(Footer);
