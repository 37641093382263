import React from 'react'
import { ContentfulDownloadButton } from '../BlockLibrary/ContentfulDownloadButton';
import { RichText } from '../richTextOptions';
import { Image } from '../BlockLibrary/Common/Image';

const ContentfulBlockTextDownloadTemplate = ({ text, withButton }) => {
	return (
		<div className={"claim-step " + (!withButton ? ' step2 ' : '')}>
			<Image image={text.optionalIcon} />
			<div className="claim-step-text">
				<RichText data={text.richText}></RichText>
				{
					withButton == true && (
						<ContentfulDownloadButton></ContentfulDownloadButton>
					)
				}
			</div>
		</div>
	);
}


const ContentfulBlockTextDefault = ({ text }) => {
	if (text.backgroundColor) {
		return (
			<div className="banner-content" style={{ backgroundColor: text.backgroundColor }}>
				<RichText data={text.richText}></RichText>
			</div>
		)
	}

	return <RichText data={text.richText}></RichText>
}

const ContentfulBlockText = text => {
	var content = '';
	switch (text.template) {
		case 'DownloadStep':
			content = <ContentfulBlockTextDownloadTemplate withButton={false} text={text}></ContentfulBlockTextDownloadTemplate>
			break;
		case 'DownloadStepWithButton':
			content = <ContentfulBlockTextDownloadTemplate withButton={true} text={text}></ContentfulBlockTextDownloadTemplate>
			break;
		default:
			content = <ContentfulBlockTextDefault text={text}></ContentfulBlockTextDefault>;
			break;
	}
	return (
		<>
			{content}
		</>
	)
}

export { ContentfulBlockText }
