import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Block from '../block'
import { RichText, HtmlCode } from '../richTextOptions'
import { Image } from '../BlockLibrary/Common/Image'
import { QuoteForm } from '../BlockLibrary/Common/QuoteForm'
import { getIsPreviewStatus } from '../../state/app'
import Modal from 'react-bootstrap/Modal'

const VideoModal = ({ videoScript, isVisible, closeModal }) => {
     return (
          <Modal
               show={isVisible}
               onHide={closeModal}
               backdropClassName="landing-video-backdrop"
               dialogClassName="landing-video-modal"
               centered
          >
               <Modal.Body>
                    <a
                         className="video-close-btn"
                         onClick={closeModal}
                         aria-label="Close"
                    >
                         <i className="fas fa-times"></i>
                    </a>
                    <HtmlCode data={videoScript}></HtmlCode>
               </Modal.Body>
          </Modal>
     )
}

class LandingQuoteVersion1 extends Component {
     state = {
          isVisible: false,
     }

     showModal = e => {
          e.preventDefault();
          this.setState({ isVisible: true });
     }

     closeModal = () => this.setState({ isVisible: false })

     render() {
          const {
               contentName,
               landingH1,
               landingH2,
               benefitList,
               isQuoteForm,
               quoteButton,
               videoThumbnail,
               videoScript,
               isPreview,
          } = this.props

          if (typeof videoThumbnail != 'undefined') {
               var hasThumbnail = isPreview ? videoThumbnail.fields.file : videoThumbnail.file;
          }
          return (
               <section className={`v1`}>
                    <div className="container">
                         <div className="landing-quote-form-container">
                              {landingH1 && <h1>{landingH1}</h1>}
                              {landingH2 && <h2>{landingH2}</h2>}
                              <div className="row">
                                   <div className="col-lg-7">
                                        <RichText data={benefitList} />
                                   </div>
                                   <div className="col-lg-5">
                                        {isQuoteForm ? <QuoteForm contentName={contentName} quoteButton={quoteButton} /> : <Block block={quoteButton} />}
                                   </div>
                              </div>
                         </div>
                         <div className="landing-video">
                              {hasThumbnail && (
                                   <>
                                        <div className="landing-video-thumbnail">
                                             <Image image={videoThumbnail} />
                                        </div>
                                        <div className="landing-video-button-play">
                                             <a href="#" onClick={this.showModal}>
                                                  <i className="fas fa-play"></i>
                                             </a>
                                        </div>
                                   </>
                              )}
                         </div>
                    </div>
                    {videoScript && <VideoModal videoScript={videoScript} isVisible={this.state.isVisible} closeModal={this.closeModal} />}
               </section>
          )
     }
}

class LandingQuoteVersion2 extends Component {
     state = {
          isVisible: false,
     }

     showModal = e => {
          e.preventDefault();
          this.setState({ isVisible: true });
     }

     closeModal = () => this.setState({ isVisible: false })

     render() {
          const {
               contentName,
               landingH1,
               landingH2,
               benefitList,
               isQuoteForm,
               quoteButton,
               videoThumbnail,
               videoScript,
               isPreview,
          } = this.props

          const videoThumbnailUrl = videoThumbnail ? `url(${isPreview ? videoThumbnail.fields.file.url : videoThumbnail.file.url})` : null;

          return (
               <section className={`v2`}>
                    {(landingH1 || landingH2) && (
                         <div className="container text-center">
                              {landingH1 && <h1>{landingH1}</h1>}
                              {landingH2 && <h2>{landingH2}</h2>}
                         </div>
                    )}
                    <div className="row">
                         <div className="col-lg-6">
                              <div className={`landing-quote-form-container ${!isQuoteForm ? 'ppc-b-video' : ''}`}>
                                   <RichText data={benefitList} />
                                   {isQuoteForm ? <QuoteForm contentName={contentName} quoteButton={quoteButton} /> : <Block block={quoteButton} />}
                              </div>
                         </div>
                         <div className="col-lg-6">
                              <div className="landing-video">
                                   {videoThumbnailUrl && (
                                        <>
                                             <div className="landing-video-thumbnail" style={{ backgroundImage: videoThumbnailUrl }}>
                                                  {/* <Image image={videoThumbnail} /> */}
                                             </div>
                                             <div className="landing-video-button-play">
                                                  <a href="#" onClick={this.showModal}>
                                                       <i className="fas fa-play"></i>
                                                  </a>
                                             </div>
                                        </>
                                   )}
                              </div>
                         </div>
                    </div>
                    {videoScript && (
                         <VideoModal
                              videoScript={videoScript}
                              isVisible={this.state.isVisible}
                              closeModal={this.closeModal}
                         />
                    )}
               </section>
          )
     }
}

class LandingQuoteVersion3 extends Component {
     render() {
          const {
               contentName,
               landingH1,
               landingH2,
               benefitList,
               isQuoteForm,
               quoteButton,
               videoScript,
               backgroundImage,
               isPreview
          } = this.props;

          const backgroundImageUrl = backgroundImage ? `url(${isPreview ? backgroundImage.fields.file.url : backgroundImage.file.url})` : null;

          return (
               <section className={`v3`} style={{ backgroundImage: backgroundImageUrl }}>
                    <div className="container">
                         {
                              (landingH1 || landingH2) && (
                                   <div className="intro-text text-center">
                                        {landingH1 && <h1>{landingH1}</h1>}
                                        {landingH2 && <h2>{landingH2}</h2>}
                                   </div>
                              )
                         }
                         <div className="landing-main-content">
                              <div className="row">
                                   <div className="col-lg-6">
                                        <div className="landing-quote-form-container">
                                             <RichText data={benefitList} />
                                             {isQuoteForm ? <QuoteForm contentName={contentName} quoteButton={quoteButton} /> : <Block block={quoteButton} />}
                                        </div>
                                   </div>
                                   <div className="col-lg-6">
                                        <div className="landing-video">
                                             {(isPreview ? videoScript : videoScript.videoScript) && (<HtmlCode data={videoScript}></HtmlCode>)}
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section >
          )
     }
}

class SectionLandingQuote extends Component {
     constructor(props) {
          super(props)

          this.state = {
               content: null,
          }
     }

     componentDidMount() {
          const script1 = document.createElement('script')
          const script2 = document.createElement('script')

          script1.src = 'https://fast.wistia.com/embed/medias/videolink.jsonp'
          script1.async = true

          script2.src = 'https://fast.wistia.com/assets/external/E-v1.js'
          script2.async = true

          document.body.appendChild(script1)
          document.body.appendChild(script2)

          var content = '';

          switch (this.props.template) {
               case 'Version 1':
                    content = <LandingQuoteVersion1 {...this.props} />
                    break
               case 'Version 2':
                    content = <LandingQuoteVersion2 {...this.props} />
                    break
               case 'Version 3':
                    content = <LandingQuoteVersion3 {...this.props} />
                    break
               default:
                    content = <LandingQuoteVersion1 {...this.props} />
          }
          this.setState({ content })
     }

     render() {
          return <>{this.state.content}</>
     }
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state),
     }
}

SectionLandingQuote.propTypes = {
     isPreview: PropTypes.bool,
}
const ContentfulSectionLandingQuote = connect(mapStateToProps)(
     SectionLandingQuote
)

export { ContentfulSectionLandingQuote }
