import React from 'react';
const ContentfulAsset = (asset) => {
     return (
          <>
               {asset.file && (
                    <a target="_blank" href={asset.file.url} >{asset.title}</a>
               )}
          </>
     )
}

export { ContentfulAsset }
