import React from 'react';
import { Image } from './Common/Image';
import { addSlashToStr } from '../utilities';

const ContentfulBlockImage = (blockImage) => {
     let imageUrl = '';

     if (blockImage.imageUrl) {
          imageUrl = blockImage.imageUrl

          if (blockImage.urlToUse == 'Internal URL') {
               imageUrl = addSlashToStr(imageUrl);
          }

          return (
               <a href={imageUrl} target={blockImage.urlTarget == 'New Tab' ? '_blank' : null}>
                    <Image image={blockImage.image}></Image>
               </a>
          )
     } else {
          return (
               <Image image={blockImage.image}></Image>
          )
     }
}

export { ContentfulBlockImage }
