import React from 'react';
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { ContentfulSectionDisplayForm, ContentfulNavigationButton, ContentfulBlockCode } from '../components/BlockLibrary/';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../state/app';
import { ContentfulBlockTable, ContentfulBlockTableScroll, ContentfulBlockListImages } from './BlockLibrary';
import ArticleTab from './BlockLibrary/Healthzone/ArticleTab/ArticleTab'

import { changeTrackingCode } from '../components/BlockLibrary/Common/changeTrackingCode'
import { getTrackingCode, getInfoArticle, getTrackingPhone } from '../state/app';


const Text = ({ node, children }) => {
     const isPreview = useSelector(state => state.app.isPreview);
     const infoArticle = isPreview ? useSelector(state => state.app.previewPage.fields.infoArticle) : useSelector(state => state.app.infoArticle);

     if (typeof node.content[0].value != 'undefined' && node.content[0].value.type == 'div') {
          return <ArticleTab isPreview={isPreview} infoArticle={infoArticle}></ArticleTab>
     } else {
          return (
               <>{
                    (React.Children.count(children) && children != '') && (
                         <p>{children}</p>
                    )
               }</>
          )
     }
}

const LIST_ITEM = ({ children }) => {
     return (
          <>{
               (React.Children.count(children) && children != '') && (
                    <li><span>{children}</span></li>
               )
          }</>
     )
}

function getLocation(href) {
     var match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
     return match && {
          href: href,
          protocol: match[1],
          host: match[2],
          hostname: match[3],
          port: match[4],
          pathname: match[5],
          search: match[6],
          hash: match[7]
     }
}

const HyperLink = ({ url, children }) => {
     // var ariaLabel = children;
     // if (typeof children[0] !== 'undefined' && typeof children[0] === 'object') {
     //      ariaLabel = children[0].props.children;
     // }
     const trackingCode = useSelector(state => state.app.trackingCode);
     const trackingPhone = useSelector(state => state.app.trackingPhone);
     let checkIntenal = (url.substr(0, 1) === '/');
     var l = getLocation(url)
     let target = '_blank';
     if (checkIntenal) {
          target = '_self'
     } else {
          if ((typeof location != 'undefined' && l != null && typeof l.hostname != 'undefined' && location.hostname === l.hostname) || l == null) {
               target = "_self";
          } else {
               target = "_blank";
          }
     }
     const str = url.split("/")
     let strUrl = ''
     if (str[str.length - 1].includes("#")) {
          if (checkIntenal) {
               strUrl = url
          }
          else {
               strUrl = changeTrackingCode(url, trackingCode, trackingPhone)
          }
     }
     else {
          if (checkIntenal) {
               strUrl = (url.charAt(url.length - 1) == '/' ? url : (url + '/'))
          }
          else {
               strUrl = changeTrackingCode(url, trackingCode, trackingPhone)
          }
     }

     return (
          <a href={strUrl} target={target}>{children}</a>
     )

}

function isValidImageURL(str) {
     if (typeof str !== 'string') return false;
     return !!str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
}

const EMBEDDED_ASSET = ({ node }) => {
     var imageSrc = '';
     var imageTitle = '';
     var imageDescription = '';

     if (typeof node.data.target.fields != 'undefined' && node.data.target.fields.file) {
          if (typeof node.data.target.fields.file.url == 'undefined') {
               imageSrc = node.data.target.fields.file['en-US'].url;
          }
          else {
               imageSrc = node.data.target.fields.file.url;
          }
     }
     if (!isValidImageURL(imageSrc)) {
          return <></>;
     }
     if (typeof node.data.target.fields != 'undefined' && node.data.target.fields.title) {
          if (typeof node.data.target.fields.title["en-US"] == 'undefined') {
               imageTitle = node.data.target.fields.title;
          }
          else {
               imageTitle = node.data.target.fields.title["en-US"];
          }
     }
     if (typeof node.data.target.fields != 'undefined' && node.data.target.fields.description) {
          if (typeof node.data.target.fields.description["en-US"] == 'undefined') {
               imageDescription = node.data.target.fields.description;
          }
          else {
               imageDescription = node.data.target.fields.description["en-US"];
          }
     }
     else {
          imageDescription = node.data.target.fields.description;
     }

     if (typeof node.data.target.fields.file["en-US"] != 'undefined') {
          var isSmallImg = node.data.target.fields.file["en-US"].details.image ? (node.data.target.fields.file["en-US"].details.image.width > 325 ? false : true) : true;
     } else {
          var isSmallImg = node.data.target.fields.file.details.image ? (node.data.target.fields.file.details.image.width > 325 ? false : true) : true;
     }

     return (
          <>
               {imageSrc && (
                    <div className={`article-body-img ${isSmallImg ? "img-right" : ""}`}><img src={imageSrc} alt={(typeof imageDescription === '' || typeof imageDescription === null || typeof imageDescription === 'undefined') ? imageTitle : imageDescription}></img></div>
               )}
          </>
     )
}

const BoldMark = ({ children }) => {
     return (
          <>
               {
                    (React.Children.count(children) && children != '') && (
                         <strong>{children}</strong>
                    )
               }
          </>
     )
}

const ItalicMark = ({ children }) => {
     return (
          <>
               {
                    (React.Children.count(children) && children != '') && (
                         <em>{children}</em>
                    )
               }
          </>
     )
}

const options = {
     renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => <Text node={node}>{children}</Text>,
          [BLOCKS.LIST_ITEM]: (node, children) => {
               return <LIST_ITEM>{children}</LIST_ITEM>
          },
          [INLINES.HYPERLINK]: (node, children) => {
               return <HyperLink url={node.data.uri}>{children}</HyperLink>;
          },
          [BLOCKS.EMBEDDED_ASSET]: (node) => {
               return <EMBEDDED_ASSET node={node}></EMBEDDED_ASSET>;
          },
          [BLOCKS.EMBEDDED_ENTRY]: (node) => {
               if (typeof node.data.target.sys != 'undefined' || typeof node.data.target.sys.contentType.sys != 'undefined') {
                    var contentType = node.data.target.sys.contentType.sys.id;
               }
               var _return = '';
               switch (contentType) {
                    case 'navigationButton':
                         const dataButton = node.data.target.fields;
                         _return = <ContentfulNavigationButton buttonRichText={dataButton}></ContentfulNavigationButton>
                         break;
                    case 'blockDisplayForm':
                         _return = <ContentfulSectionDisplayForm ariaLabel="download a claim form" block={node.data.target.fields}></ContentfulSectionDisplayForm>;
                         break;
                    case 'sectionDisplayForm':
                         _return = <ContentfulSectionDisplayForm ariaLabel="download a claim form" block={node.data.target.fields}></ContentfulSectionDisplayForm>;
                         break;
                    case 'blockHtml':
                         const { content } = node.data.target.fields;
                         _return = <div dangerouslySetInnerHTML={{
                              __html: content['en-US'] ? content['en-US'] : content // Preview is content, not preview is content['en-US']
                         }}></div>
                         break;
                    case 'blockCode':
                         const { script } = node.data.target.fields;
                         _return = <ContentfulBlockCode script={script['en-US'] ? script['en-US'] : script}></ContentfulBlockCode> // Preview is script, not preview is script['en-US']
                         break;
                    case 'blockTable':
                         const { fields } = node.data.target;
                         _return = <div className="table-rich-text"><ContentfulBlockTable data={fields}></ContentfulBlockTable></div>
                         break;
                    case 'blockTableScroll':
                         const fieldsScroll = node.data.target.fields;
                         _return = <div className="table-rich-text"><ContentfulBlockTableScroll data={fieldsScroll}></ContentfulBlockTableScroll></div>
                         break;
                    case 'blockListImages':
                         const fieldsImages = node.data.target.fields;
                         _return = <ContentfulBlockListImages data={fieldsImages}></ContentfulBlockListImages>
                         break;
               }
               return _return;
          }
     },
     renderMark: {
          [MARKS.BOLD]: (children) => {
               return <BoldMark>{children}</BoldMark>
          },
          [MARKS.ITALIC]: (children) => {
               return <ItalicMark>{children}</ItalicMark>
          }
     }
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state),
          trackingCode: getTrackingCode(state),
          trackingPhone: getTrackingPhone(state),
          infoArticle: getInfoArticle(state)
     };
}

const Rich = (data) => {
     if (typeof data.data === 'undefined') return <></>;
     if (data.isPreview) {
          data.data.content.map(array => {
               if (array.nodeType == 'unordered-list' || array.nodeType == 'ordered-list') {
                    array.content.map(array =>
                         array.content[0].nodeType = 'TextList'
                    )
               }
               if (array.nodeType == "paragraph") {
                    array.content.map(array => {
                         if (array.value == "###TabContentArticle###") {
                              array.value = <div></div>
                         }
                    })
               }
          });
     } else {
          data.data.json.content.map(array => {
               if (array.nodeType == 'unordered-list' || array.nodeType == 'ordered-list') {
                    array.content.map(array =>
                         array.content[0].nodeType = 'TextList'
                    )
               }
               if (array.nodeType == "paragraph") {
                    array.content.map(array => {
                         if (array.value == "###TabContentArticle###") {
                              array.value = <div></div>
                         }
                    })
               }
          });
     }
     return (
          data.isPreview != true ? (
               documentToReactComponents(data.data.json, options)
          ) : (
                    documentToReactComponents(data.data, options)
               )
     );
}

Rich.propTypes = {
     isPreview: PropTypes.bool,
}

const Code = ({ data, className, isPreview, trackingCode, trackingPhone }) => {
     if (typeof data === 'undefined') return <></>;
     return (
          isPreview != true ? (
               data.childMarkdownRemark ? (
                    <div className={className} dangerouslySetInnerHTML={{
                         __html: changeTrackingCode(data.childMarkdownRemark.html, trackingCode, trackingPhone)
                    }}></div>
               ) : (
                         <div className={className} dangerouslySetInnerHTML={{
                              __html: data
                         }}></div>
                    )
          ) : (
                    <div className={className} dangerouslySetInnerHTML={{
                         __html: data
                    }}></div>
               )
     );
}

Code.propTypes = {
     isPreview: PropTypes.bool,
}

const RichText = connect(mapStateToProps)(Rich);
const HtmlCode = connect(mapStateToProps)(Code);

export { options, RichText, HtmlCode }
