import React from 'react'
import Block from '../block'

const SectionGridDefault = ({ grid }) => {
	return (
		<>
			{grid != 'undefined' && grid.blocksInColumns[0].map((column, i) => (<Block block={column} key={i} />))}
		</>
	)
}

const SectionGrid2Column = ({ section }) => {
	var col0 = typeof section.blocksInColumns[0] != 'undefined' ? section.blocksInColumns[0] : null
	var col1 = typeof section.blocksInColumns[1] != 'undefined' ? section.blocksInColumns[1] : null
	return (
		<div className="row">
			{col0 != null && (
				<div className="col-lg-6">
					<Block block={col0}></Block>
				</div>
			)}
			{col1 != null && (
				<div className="col-lg-6">
					<Block block={col1}></Block>
				</div>
			)}
		</div>
	)
}

const SectionGrid3Column = ({ section }) => {
	var col0 = typeof section.blocksInColumns[0] != 'undefined' ? section.blocksInColumns[0] : null
	var col1 = typeof section.blocksInColumns[1] != 'undefined' ? section.blocksInColumns[1] : null
	var col2 = typeof section.blocksInColumns[2] != 'undefined' ? section.blocksInColumns[2] : null
	return (
		<div className="row">
			{col0 != null && (
				<div className="col-sm-6 col-md-4">
					<Block block={col0}></Block>
				</div>
			)}
			{col1 != null && (
				<div className="col-sm-6 col-md-4">
					<Block block={col1}></Block>
				</div>
			)}
			{col2 != null && (
				<div className="col-sm-6 col-md-4">
					<Block block={col2}></Block>
				</div>
			)}
		</div>
	)
}

const SectionGrid4Column = ({ section }) => {
	var col0 = typeof section.blocksInColumns[0] != 'undefined' ? section.blocksInColumns[0] : null
	var col1 = typeof section.blocksInColumns[1] != 'undefined' ? section.blocksInColumns[1] : null
	var col2 = typeof section.blocksInColumns[2] != 'undefined' ? section.blocksInColumns[2] : null
	var col3 = typeof section.blocksInColumns[3] != 'undefined' ? section.blocksInColumns[3] : null
	return (
		<div className="row">
			{col0 != null && (
				<div className="col-lg-3">
					<Block block={col0}></Block>
				</div>
			)}
			{col1 != null && (
				<div className="col-lg-3">
					<Block block={col1}></Block>
				</div>
			)}
			{col2 != null && (
				<div className="col-lg-3">
					<Block block={col2}></Block>
				</div>
			)}
			{col3 != null && (
				<div className="col-lg-3">
					<Block block={col3}></Block>
				</div>
			)}
		</div>
	)
}

const SectionGrid2Column48 = ({ section }) => {
	var col0 = typeof section.blocksInColumns[0] != 'undefined' ? section.blocksInColumns[0] : null
	var col1 = typeof section.blocksInColumns[1] != 'undefined' ? section.blocksInColumns[1] : null
	return (
		<div className="row">
			{col0 != null && (
				<div className="col-lg-4">
					<Block block={col0}></Block>
				</div>
			)}
			{col1 != null && (
				<div className="col-lg-8">
					<Block block={col1}></Block>
				</div>
			)}
		</div>
	)
}

const SectionGrid2Column84 = ({ section }) => {
	var col0 = typeof section.blocksInColumns[0] != 'undefined' ? section.blocksInColumns[0] : null
	var col1 = typeof section.blocksInColumns[1] != 'undefined' ? section.blocksInColumns[1] : null
	return (
		<div className="row">
			{col0 != null && (
				<div className="col-lg-8">
					<Block block={col0}></Block>
				</div>
			)}
			{col1 != null && (
				<div className="col-lg-4">
					<Block block={col1}></Block>
				</div>
			)}
		</div>
	)
}

const SectionGrid3Column1Row = ({section}) => {
	var column = section.blocksInColumns.map((col, key) => {
		return (
			<div className='col-sm-6 col-lg-4' key={key}>
				<Block block={col}></Block>
			</div>
		)
	})
	return <div className="row">{column}</div>
}

const ContentfulSectionGrid = (grid) => {
	var content = '';

	switch (grid.numberOfColumns) {
		case '2-Column':
			content = <SectionGrid2Column section={grid} />
			break;
		case '3-Column':
			content = <SectionGrid3Column section={grid} />
			break;
		case '4-Column':
			content = <SectionGrid4Column section={grid} />
			break;
		case '2-Column-4/8':
			content = <SectionGrid2Column48 section={grid} />
			break;
		case '2-Column-8/4':
			content = <SectionGrid2Column84 section={grid} />
			break;
		case '3-Column-1-Row':
			content = <SectionGrid3Column1Row section={grid} />
			break;
		default:
			content = <SectionGridDefault section={grid} />
	}

	return (
		<>
			{/* {grid.blocksInColumns.map((column, i) => (
				<div className={`${numberOfColumns} list-item`} key={i}>
					<Block block={column} />
				</div>
			))} */}
			{content}
		</>
	)
}

export { ContentfulSectionGrid }
