import React, { Component } from 'react';
import { HtmlCode } from '../richTextOptions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsPreviewStatus } from '../../state/app';

class BlockCode extends Component {
     constructor(props) {
          super(props);

          if (typeof document != 'undefined') {
               if (props.isPreview) {
                    this.fbScript = props.script.includes('embedsocial-album') ? function (d, s, id) {
                         var js;
                         if (d.getElementById(id)) { return; }
                         js = d.createElement(s);
                         js.id = id;
                         js.src = "https://embedsocial.com/embedscript/ei.js";
                         d.getElementsByTagName("head")[0].appendChild(js);
                    }(document, "script", "EmbedSocialScript") : '';
               } else {
                    this.fbScript = props.script.script.includes('embedsocial-album') ? function (d, s, id) {
                         var js;
                         if (d.getElementById(id)) { return; }
                         js = d.createElement(s);
                         js.id = id;
                         js.src = "https://embedsocial.com/embedscript/ei.js";
                         d.getElementsByTagName("head")[0].appendChild(js);
                    }(document, "script", "EmbedSocialScript") : '';
               }
          }
     }

     componentDidMount() {
          const script1 = document.createElement("script");
          const script2 = document.createElement("script");
          const script3 = document.createElement("script");

          script1.src = "https://fast.wistia.com/embed/medias/videolink.jsonp";
          script1.async = true;

          script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
          script2.async = true;

          script3.src = "https://platform.twitter.com/widgets.js";

          document.body.appendChild(script1);
          document.body.appendChild(script2);
          document.body.appendChild(script3);
     }

     render() {
          const { script } = this.props;

          return (
               <>
                    {
                         script && <HtmlCode data={script}></HtmlCode>
                    }
                    {this.fbScript && <script>{fbScript}</script>}
               </>
          );
     }
};

BlockCode.propTypes = {
     isPreview: PropTypes.bool
}

const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     }
}

const ContentfulBlockCode = connect(mapStateToProps)(BlockCode);

export { ContentfulBlockCode }

