import React from 'react';
import { Image } from './Common/Image';
import { addSlashToStr } from '../utilities';

const ContentfulNavigationSocial = (socialItem) => {
     let url = null;

     if (socialItem.urlToUse == 'Internal URL') {
          url = socialItem.internalUrl ? addSlashToStr(socialItem.internalUrl) : null;
     } else {
          url = socialItem.externalUrl ? socialItem.externalUrl : null;
     }

     return (
          <>
               <a href={url} target={(socialItem.urlTarget) == "New Tab" ? "_blank" : null}>
                    <Image image={socialItem.icon}></Image>
               </a>
          </>
     );
};

export { ContentfulNavigationSocial }