import React from 'react';
import Block from '../block';
import { RichText } from '../richTextOptions';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';

const SectionContainerDefault = ({ section }) => {
     return (
          <div className="container">
               {section.headerDescription && <RichText data={section.headerDescription}></RichText>}
               <div className="content">
                    {section.blocks &&
                         section.blocks.map((block, i) => {
                              return <Block block={block} key={i}></Block>
                         })}
               </div>
          </div>
     )
}

const SectionContainerFullWidth = ({ section }) => {
     return (
          <div className="container-fullwidth">
               {section.headerDescription && <RichText data={section.headerDescription}></RichText>}
               {section.blocks &&
                    section.blocks.map((block, i) => {
                         return <Block block={block} key={i}></Block>
                    })}
          </div>
     )
}

const SectionContainerNoFullNoColumn = ({ section }) => {
     return (
          <div className="container">
               {section.headerDescription && <RichText data={section.headerDescription}></RichText>}
               <div className="row">
                    <div className="col-md-12">
                         {section.blocks &&
                              section.blocks.map((block, i) => {
                                   return <Block block={block} key={i}></Block>
                              })}
                    </div>
               </div>
          </div>
     )
}

const SectionContainer2Column = ({ section }) => {

     var col0 = typeof section.blocks[0] != 'undefined' ? section.blocks[0] : null
     var col1 = typeof section.blocks[1] != 'undefined' ? section.blocks[1] : null
     return (
          <div className="container">
               {section.headerDescription && <RichText data={section.headerDescription}></RichText>}
               <div className="row">
                    {col0 != null && (
                         <div className="col-lg-6 col-left-2col">
                              <Block block={col0}></Block>
                         </div>
                    )}
                    {col1 != null && (
                         <div className="col-lg-6 col-right-2col">
                              <div className="col-right-container">
                                   <Block block={col1}></Block>
                              </div>
                         </div>
                    )}
               </div>
          </div>
     )
}

const SectionContainer2Column57 = ({ section }) => {

     var col0 = typeof section.blocks[0] != 'undefined' ? section.blocks[0] : null
     var col1 = typeof section.blocks[1] != 'undefined' ? section.blocks[1] : null
     return (
          <div className="container">
               {section.headerDescription && <RichText data={section.headerDescription}></RichText>}
               <div className="row">
                    {col0 != null && (
                         <div className="col-lg-5 col-left-2col">
                              <Block block={col0}></Block>
                         </div>
                    )}
                    {col1 != null && (
                         <div className="col-lg-7 col-right-2col">
                              <div className="col-right-container">
                                   <Block block={col1}></Block>
                              </div>
                         </div>
                    )}
               </div>
          </div>
     )
}

const SectionContainer2ColumnMd = ({ section }) => {

     var col0 = typeof section.blocks[0] != 'undefined' ? section.blocks[0] : null
     var col1 = typeof section.blocks[1] != 'undefined' ? section.blocks[1] : null
     return (
          <div className="container">
               {section.headerDescription && <RichText data={section.headerDescription}></RichText>}
               <div className="row">
                    {col0 != null && (
                         <div className="col-md-4 col-lg-6 col-left-2col">
                              <Block block={col0}></Block>
                         </div>
                    )}
                    {col1 != null && (
                         <div className="col-md-8 col-lg-6 col-right-2col">
                              <div className="col-right-container">
                                   <Block block={col1}></Block>
                              </div>
                         </div>
                    )}
               </div>
          </div>
     )
}

const SectionContainer2Column75 = ({ section }) => {
     var col0 = typeof section.blocks[0] != 'undefined' ? section.blocks[0] : null
     var col1 = typeof section.blocks[1] != 'undefined' ? section.blocks[1] : null
     return (
          <div className="container">
               {section.headerDescription && <RichText data={section.headerDescription}></RichText>}
               <div className="row">
                    {col0 != null && (
                         <div className="col-lg-7">
                              <div className="col-left-container">
                                   <Block block={col0}></Block>
                              </div>
                         </div>
                    )}
                    {col1 != null && (
                         <div className="col-lg-5">
                              <Block block={col1}></Block>
                         </div>
                    )}
               </div>
          </div>
     )
}

const SectionContainer2Column84 = ({ section }) => {
     var col0 = typeof section.blocks[0] != 'undefined' ? section.blocks[0] : null
     var col1 = typeof section.blocks[1] != 'undefined' ? section.blocks[1] : null
     return (
          <div className="container">
               {section.headerDescription && <RichText data={section.headerDescription}></RichText>}
               <div className="row">
                    {col0 != null && (
                         <div className="col-md-8">
                              <Block block={col0}></Block>
                         </div>
                    )}
                    {col1 != null && (
                         <div className="col-md-4">
                              <Block block={col1}></Block>
                         </div>
                    )}
               </div>
          </div>
     )
}

const SectionContainer2Column84Lg = ({ section }) => {
     var col0 = typeof section.blocks[0] != 'undefined' ? section.blocks[0] : null
     var col1 = typeof section.blocks[1] != 'undefined' ? section.blocks[1] : null
     return (
          <div className="container">
               {section.headerDescription && <RichText data={section.headerDescription}></RichText>}
               <div className="row">
                    {col0 != null && (
                         <div className="col-lg-8">
                              <Block block={col0}></Block>
                         </div>
                    )}
                    {col1 != null && (
                         <div className="col-lg-4">
                              <Block block={col1}></Block>
                         </div>
                    )}
               </div>
          </div>
     )
}

const SectionContainer2Column48 = ({ section }) => {
     var col0 = typeof section.blocks[0] != 'undefined' ? section.blocks[0] : null
     var col1 = typeof section.blocks[1] != 'undefined' ? section.blocks[1] : null
     return (
          <div className="container">
               {section.headerDescription && <RichText data={section.headerDescription}></RichText>}
               <div className="row">
                    {col0 != null && (
                         <div className="col-md-4">
                              <Block block={col0}></Block>
                         </div>
                    )}
                    {col1 != null && (
                         <div className="col-md-8">
                              <Block block={col1}></Block>
                         </div>
                    )}
               </div>
          </div>
     )
}

const SectionContainer2Column813Md = ({ section }) => {
     var col0 = typeof section.blocks[0] != 'undefined' ? section.blocks[0] : null
     var col1 = typeof section.blocks[1] != 'undefined' ? section.blocks[1] : null
     return (
          <div className="container">
               <div className="row">
                    {col0 != null && (
                         <div className="col-md-8">
                              <Block block={col0}></Block>
                         </div>
                    )}
                    <div className="col-md-1 d-md-none d-lg-block">
                    </div>
                    {col1 != null && (
                         <div className="col-md-4 col-lg-3">
                              <Block block={col1}></Block>
                         </div>
                    )}
               </div>
          </div>
     )
}

const SectionContainer3Column = ({ section }) => {
     var col0 = typeof section.blocks[0] != 'undefined' ? section.blocks[0] : null
     var col1 = typeof section.blocks[1] != 'undefined' ? section.blocks[1] : null
     var col2 = typeof section.blocks[2] != 'undefined' ? section.blocks[2] : null
     return (
          <div className="container">
               {section.headerDescription && <RichText data={section.headerDescription}></RichText>}
               <div className="row">
                    {col0 != null && (
                         <div className="col-lg-4">
                              <Block block={col0}></Block>
                         </div>
                    )}
                    {col1 != null && (
                         <div className="col-lg-4">
                              <Block block={col1}></Block>
                         </div>
                    )}
                    {col2 != null && (
                         <div className="col-lg-4">
                              <Block block={col2}></Block>
                         </div>
                    )}
               </div>
          </div>
     )
}

const SectionContainer3ColumnHeader = ({ section }) => {
     var col0 = typeof section.blocks[0] != 'undefined' ? section.blocks[0] : null
     var col1 = typeof section.blocks[1] != 'undefined' ? section.blocks[1] : null
     var col2 = typeof section.blocks[2] != 'undefined' ? section.blocks[2] : null
     var col3 = typeof section.blocks[3] != 'undefined' ? section.blocks[3] : null
     return (
          <div className="container">
               <Block block={col0}></Block>
               <div className="row">
                    {col1 != null && (
                         <div className="col-lg-4">
                              <Block block={col1}></Block>
                         </div>
                    )}
                    {col2 != null && (
                         <div className="col-lg-4">
                              <Block block={col2}></Block>
                         </div>
                    )}
                    {col3 != null && (
                         <div className="col-lg-4">
                              <Block block={col3}></Block>
                         </div>
                    )}
               </div>
          </div>
     )
}

const SectionContainerFullWidth2Column = ({ section }) => {
     var col0 = typeof section.blocks[0] != 'undefined' ? section.blocks[0] : null
     var col1 = typeof section.blocks[1] != 'undefined' ? section.blocks[1] : null
     return (
          <div className="row">
               {section.headerDescription && <RichText data={section.headerDescription}></RichText>}
               {col0 != null && (
                    <div className="col-lg-6">
                         <Block block={col0}></Block>
                    </div>
               )}
               {col1 != null && (
                    <div className="col-lg-6">
                         <Block block={col1}></Block>
                    </div>
               )}
          </div>
     )
}

const SectionContainer2ColumnBorderBetween = ({ section }) => {
     var col0 = typeof section.blocks[0] != 'undefined' ? section.blocks[0] : null
     var col1 = typeof section.blocks[1] != 'undefined' ? section.blocks[1] : null
     var col2 = typeof section.blocks[2] != 'undefined' ? section.blocks[2] : null
     var col3 = typeof section.blocks[3] != 'undefined' ? section.blocks[3] : null
     return (
          <div className='container'>
               <div className="headline"><Block block={col0}></Block></div>
               <div className="row">
                    {col0 != null && (
                         <div className="col-md-6">
                              <Block block={col1}></Block>
                         </div>
                    )}
                    {col1 != null && (
                         <div className="col-md-6 col-border-left">
                              <Block block={col2}></Block>
                         </div>
                    )}
               </div>
               <div className="description"><Block block={col3}></Block></div>
          </div>
     )
}

const SectionContainer = section => {
     var content = '';

     if (typeof section.blocks != 'undefined' && section.blocks != null) {
          switch (section.sectionStyle) {
               case '2-Column':
                    content = (
                         <SectionContainer2Column section={section}></SectionContainer2Column>
                    )
                    break;
               case '2-Column-Md':
                    content = (
                         <SectionContainer2ColumnMd section={section}></SectionContainer2ColumnMd>
                    )
                    break;
               case '2-Column-5/7':
                    content = (
                         <SectionContainer2Column57 section={section}></SectionContainer2Column57>
                    )
                    break;
               case '2-Column-7/5':
                    content = (
                         <SectionContainer2Column75 section={section}></SectionContainer2Column75>
                    )
                    break;
               case '2-Column-8/4':
                    content = (
                         <SectionContainer2Column84 section={section}></SectionContainer2Column84>
                    )
                    break;
               case '2-Column-8/4-Lg':
                    content = (
                         <SectionContainer2Column84Lg section={section}></SectionContainer2Column84Lg>
                    )
                    break;
               case '2-Column-4/8':
                    content = (
                         <SectionContainer2Column48 section={section}></SectionContainer2Column48>
                    )
                    break;
               case '2-Column-8/1/3-md':
                    content = (
                         <SectionContainer2Column813Md section={section}></SectionContainer2Column813Md>
                    )
                    break;
               case '3-Column':
                    content = (
                         <SectionContainer3Column section={section}></SectionContainer3Column>
                    )
                    break;
               case '3-Column-Header':
                    content = (
                         <SectionContainer3ColumnHeader section={section}></SectionContainer3ColumnHeader>
                    )
                    break;
               case 'NoFull-NoColumn':
                    content = (
                         <SectionContainerNoFullNoColumn section={section}></SectionContainerNoFullNoColumn>
                    )
                    break;
               case 'FullWidth':
                    content = (
                         <SectionContainerFullWidth section={section}></SectionContainerFullWidth>
                    )
                    break;
               case 'FullWidth-2-Column':
                    content = (
                         <SectionContainerFullWidth2Column section={section}></SectionContainerFullWidth2Column>
                    )
                    break;
               case '2-Column-Border-Between':
                    content = (
                         <SectionContainer2ColumnBorderBetween section={section}></SectionContainer2ColumnBorderBetween>
                    )
                    break;
               default:
                    content = (
                         <SectionContainerDefault section={section}></SectionContainerDefault>
                    )
                    break;
          }
     }

     let sectionClass = section.sectionClass ? section.sectionClass : null;
     let backgroundImageUrl = null;

     if (section.isPreview) {
          backgroundImageUrl = section.backgroundImage ? 'url(' + section.backgroundImage.fields.file.url + ')' : null;
     } else {
          backgroundImageUrl = section.backgroundImage ? 'url(' + section.backgroundImage.file.url + ')' : null;
     }

     return (
          <section
               className={sectionClass}
               style={{ backgroundImage: backgroundImageUrl, backgroundColor: section.backgroundColor }}>
               {content}
          </section>
     )
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

SectionContainer.propTypes = {
     isPreview: PropTypes.bool,
}
const ContentfulSectionContainer = connect(mapStateToProps)(SectionContainer);


export {
     ContentfulSectionContainer,
     SectionContainerDefault,
     SectionContainer2Column,
     SectionContainer3Column,
}
