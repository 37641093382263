import React, { Component } from 'react';
import { ContentfulBlockSelectValue } from './ContentfulBlockSelectValue';

class ContentfulBlockDiseaseDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCost: 0,
            costCalculated: 0
        };
    }

    onChangeSelect = (e) => {
        let costCalculated;
        let cost = e.target.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        if (e.target.value == '11571') {
            costCalculated = Math.ceil(e.target.value * 90 / 100);
        }
        else {
            costCalculated = Math.floor(e.target.value * 90 / 100);
        }
        let valueCalculated = costCalculated.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        this.setState({ currentCost: cost });
        this.setState({ costCalculated: valueCalculated });
    }

    render() {
        return (
            <div className='select-section'>
                <p><span>My pet went to the vet for </span>
                    <select value={this.state.CurrentCost} onChange={this.onChangeSelect} aria-label="Select Current Cost">
                        <option value="0">Select one...</option>
                        {/* {
                            this.props.selectValue.map((value, i) => (
                                <option key={i} value={value.value}>{value.name}</option>
                            ))
                        } */}
                        <ContentfulBlockSelectValue selectValue={this.props.selectValue} />
                    </select>
                    <span> which cost </span>
                    <label> ${this.state.currentCost}</label>.
                </p>
                <p><span>The Whole Pet with Wellness℠ plan covered 90% of the vet bill and I saved </span>
                    <label> ${this.state.costCalculated}</label>.
                </p>
            </div>
        );
    }
};

export { ContentfulBlockDiseaseDropdown }
