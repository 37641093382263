import React from 'react'
import Block from '../block';
import Slider from 'react-slick';
import { RichText } from '../richTextOptions';
import $ from 'jquery'

const ContentfulBlockVetsSlider = (slider) => {
    var settings = {};
    if (slider.layout == 'Icon with text') {
        settings = {
            loop: true,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 600,
            dots: true,
            arrows: true,
            infinite: true,
            slidesToShow: 7,
            slidesToScroll: 1,
            autoplayHoverPause: true,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ],
            beforeChange: (current, next) => {
                $('.vets-slider .slick-slide a').attr('tabindex', -1)
            },
            afterChange: (current, next) => {
                $('.vets-slider .slick-slide.slick-current a').attr('tabindex', 0)
            }
        };
    } else {
        settings = {
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 600,
            dots: true,
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: (current, next) => {
                $('.vets-slider .slick-slide a').attr('tabindex', -1)
            },
            afterChange: (current, next) => {
                $('.vets-slider .slick-slide.slick-current a').attr('tabindex', 0)
            }
        }
    }

    return (
        <div className={`vets-slider`}>
            {slider.headerDescription && <RichText data={slider.headerDescription} />}
            <Slider {...settings} className="tys">
                {slider.sliderItems && (
                    slider.sliderItems.map((block, i) => {
                        return (<Block key={i} block={block}></Block>)
                    })
                )}
            </Slider>
        </div>
    )
}

export { ContentfulBlockVetsSlider };
