import React from 'react'
import Block from '../block'
import { RichText } from '../richTextOptions';
import { Image } from '../BlockLibrary/Common/Image';

const ContentfulSectionGuide = (blockData) => {
	var sectionClass = '';

	switch (blockData.separator) {
		case 'Thin Line, No Margin':
			sectionClass = 'thin-line no-padding';
			break
		case 'Thin Line, Include Margin':
			sectionClass = 'thin-line include-margin';
			break
		case 'Thin Line, Include Margin, Mobile Margin':
			sectionClass = 'thin-line include-margin mobile-margin';
			break
		default:
			sectionClass = 'thin-line include-margin mobile-margin';
	}

	switch (blockData.imagePosition) {
		case 'Image Left':
			sectionClass += ' image-left';
			break;
		case 'Image Right':
			sectionClass += ' image-right';
			break;
	}

	if (blockData.guideImage.description !== null) {
	}
	return (

		<section className={`guide ${sectionClass}`}>

			<div className="container">
				<div className="row guide-wrap">
					{blockData.imagePosition == "Image Left" && (
						<div className="col-lg-4">
							<div className="image">
								{
									(blockData.guideImage) && (
										<Image image={blockData.guideImage} />
									)}
							</div>
						</div>
					)}
					<div className="col-lg-8">
						<div className="content">
							<h2 className="headline">{blockData.h3GuideHeadline}</h2>
							<div className="sub-headline">
								{
									blockData.guideDescription && (
										<RichText data={blockData.guideDescription}></RichText>
									)
								}
							</div>
							{blockData.productCards && (
								<div className="list-product-cards"> {
									blockData.productCards.map((productCard, i) => {
										return (<Block block={productCard} key={i}></Block>)
									})}
								</div>
							)}
							<div className="insider-tip">
								{
									blockData.insiderTip && (
										<RichText data={blockData.insiderTip}></RichText>
									)
								}
							</div>
						</div>
					</div>
					{blockData.imagePosition == "Image Right" && (
						<div className="col-lg-4">
							<div className="image">
								{
									(blockData.guideImage) && (
										<Image image={blockData.guideImage} />
									)}
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	)
}

export { ContentfulSectionGuide }
