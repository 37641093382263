import { useStaticQuery, graphql } from "gatsby";

export const getEmployerGroups = () => {
     const { allContentfulEmployerGroups } = useStaticQuery(
          graphql`
               query {
                    allContentfulEmployerGroups(filter: {searchable: {eq: true}, groupType: {ne: null}}, sort: {fields: groupDisplayName, order: ASC}) {
                         edges {
                              node {
                                   id
                                   contentful_id
                                   contentName
                                   groupType
                                   groupDisplayName
                                   alternateSearchTerm {
                                        alternateSearchTerm
                                   }
                                   searchable
                                   slug
                              }
                         }
                    }
               }
          `
     )

     return allContentfulEmployerGroups.edges;
}