import React from 'react';
import BlockContainer from './blockContainer';
import * as BlockLibrary from './BlockLibrary';
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../state/app';
import { connect } from "react-redux";

const contentTypes = {
     'blockIntroHero': 'ContentfulSectionIntroHero',
     'blockCoverageDetails': 'ContentfulSectionCoverageDetails',
     'blockStateForms': 'ContentfulSectionStateForms',
     'forms': 'ContentfulBlockForm',
     'homeBanner': 'ContentfulSectionHomeBanner'
}

const Block = (block) => {
     if (block.block == null) {
          return <></>
     }
     let blockData = null;
     let hadRightIcon = null;
     if (block.isPreview == true) {
          blockData = block.block.fields;

          if (block.block.sys.contentType) {
               blockData.type = typeof contentTypes[block.block.sys.contentType.sys.id] != 'undefined' ? contentTypes[block.block.sys.contentType.sys.id] : ('Contentful' + block.block.sys.contentType.sys.id.charAt(0).toUpperCase() + block.block.sys.contentType.sys.id.slice(1));
          } else {
               if (block.block.sys.type == 'Asset') {
                    blockData.type = 'ContentfulAsset';
               }
          }

     } else {
          blockData = block.block;
     }

     const BlockContent = BlockLibrary[blockData.type];

     if (typeof block.hadRightIcon != 'undefined') {
          hadRightIcon = true;
     }

     const template = block.template;
     const onClick = block.onClick;
     if (BlockContent != null) {
          return (
               <BlockContainer >
                    <BlockContent template={template} hadRightIcon={hadRightIcon} onClick={onClick} {...blockData} sys={block.block.sys}></BlockContent>
               </BlockContainer>
          )
     } else {
          return <></>
     }
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}


Block.propTypes = {
     isPreview: PropTypes.bool,
}

const ContentfulBlock = connect(mapStateToProps)(Block);

export default ContentfulBlock;
