import { useStaticQuery, graphql } from "gatsby";
export const getStateFiles = () => {
     const { allContentfulBlockForm } = useStaticQuery(
          graphql`
               query {
                    allContentfulBlockForm(filter: {statesMainClaimForm: {eq: true}}) {
                         edges {
                              node {
                                   id
                                   contentful_id
                                   formCategoryName {
                                        formCategoryName
                                   }
                                   state {
                                        stateAbbreviation
                                   }
                                   formFiles {
                                        file {
                                             url
                                        }
                                   }
                              }
                         }
                    }
               }
          `
     )

     return allContentfulBlockForm.edges;
}
