import React from 'react'

const ListImages = ({ listImages, isPreview }) => {
    return (
        <div className="list-image-logo">
            {
                listImages.map((image, i) => {
                    const imageUrl = isPreview == true ? image.fields.imageUrl : image.fields.imageUrl['en-US'];
                    const imageSrc = isPreview == true ? image.fields.image.fields.file.url : image.fields.image['en-US'].fields.file['en-US'].url;
                    const imageAlt = isPreview == true ? image.fields.image.fields.title : image.fields.image['en-US'].fields.title['en-US'];
                    const targetUrl = isPreview == true ? image.fields.urlTarget : image.fields.urlTarget['en-US'];
                    return (
                        <a href={imageUrl} target={targetUrl == 'New Tab' ? '_blank' : '_self'} key={i}>
                            <img src={imageSrc} alt={imageAlt} />
                        </a>
                    )
                })

            }
        </div>
    )
}
const ListTop20 = ({ listImages, isPreview }) => {
    return (<ul className="list-top-20">
        {
            listImages.map((image, i) => {
                const contentName = isPreview == true ? image.fields.contentName : image.fields.contentName['en-US'];
                const imageUrl = isPreview == true ? image.fields.imageUrl : image.fields.imageUrl['en-US'];
                const imageSrc = isPreview == true ? image.fields.image.fields.file.url : image.fields.image['en-US'].fields.file['en-US'].url;
                const imageAlt = isPreview == true ? image.fields.image.fields.title : image.fields.image['en-US'].fields.title['en-US'];
                const targetUrl = isPreview == true ? image.fields.urlTarget : image.fields.urlTarget['en-US'];

                return (
                    <li key={i}>
                        <img src={imageSrc} alt={imageAlt} />
                        {
                            (imageUrl) ? (
                                <>
                                    <span>{i + 1 + '.'}</span>
                                    <a href={imageUrl} target={targetUrl == 'New Tab' ? '_blank' : '_self'}>{contentName}</a>
                                </>
                            ) : (
                                    <><span>{i + 1 + '.'}</span>{contentName}</>
                                )
                        }
                    </li>
                )
            })
        }
    </ul>)
}
const ContentfulBlockListImages = data => {
    var listType = '';
    var content = '';
    var listImages = '';
    var isPreview = false;

    if (typeof data.data.listImages['en-US'] != 'undefined') {
        listImages = data.data.listImages['en-US'];
        if (data.data.hasOwnProperty('listType')) {
            listType = data.data.listType['en-US'];
        }
    } else if (typeof data.data.listImages != 'undefined') {
        listImages = data.data.listImages;
        listType = data.data.listType;
        isPreview = true;
    }
    switch (listType) {
        case 'List Top 20':
            content = (<ListTop20 listImages={listImages} isPreview={isPreview}></ListTop20>)
            break;
        default:
            content = (<ListImages listImages={listImages} isPreview={isPreview}></ListImages>)
    }
    return (
        <>{content}</>
    )
}

export { ContentfulBlockListImages }
