import React from 'react'
import { Image } from '../BlockLibrary/Common/Image';
import { RichText } from '../richTextOptions';

const ContentfulBlockAppDownload = block => {
     return (
          <div className="block-app-download">
               {block.headline && <RichText data={block.headline}></RichText>}
               <Image image={block.appImage} />
               <div className="download">
                    <a
                         href={block.playStoreUrl}
                         target="_blank"
                    >
                         <Image image={block.playStoreIcon} />
                    </a>
                    <a
                         href={block.appStoreUrl}
                         target="_blank"
                    >
                         <Image image={block.appStoreIcon} />
                    </a>
               </div>
          </div>
     )
}
export { ContentfulBlockAppDownload }
