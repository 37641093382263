import React from 'react';
import Block from '../block';

const ContentfulSectionWellnessCoveragePlans = ( { wellnessPlus, wellnessBasic } ) => {
     return (
          <section className={`wellness-coverage-plans`}>
               <div className="container">
               <div className="wellness-coverage-plans-table">
                         <table>
                              <thead>
                                   <tr>
                                        <th><img src={"/images/petwellness.png"} alt="Petwellness" className="logo-pet-wellness" /></th>
                                        <th>
                                             <p><span>{wellnessPlus.planName && wellnessPlus.planName}</span></p>
                                             ${wellnessPlus.planPrice && wellnessPlus.planPrice}/mo.*
                                        </th>
                                        <th>
                                             <p>{wellnessBasic.planName && wellnessBasic.planName}</p>
                                             ${wellnessBasic.planPrice && wellnessBasic.planPrice}/mo.*
                                        </th>
                                   </tr>
                              </thead>
                              <tbody>
                                   <tr>
                                        <td>Maximum annual benefit</td>
                                        <td>${wellnessPlus.maximumAnnualBenefit && wellnessPlus.maximumAnnualBenefit}</td>
                                        <td>${wellnessBasic.maximumAnnualBenefit && wellnessBasic.maximumAnnualBenefit}</td>
                                   </tr>
                                   <tr>
                                        <td>
                                             <p>Physical exam:</p>
                                             Two exams per policy term
                                        </td>
                                        <td>
                                             {wellnessPlus.physicalExamTwoExamsPerPolicyTerm && (
                                                  <>
                                                       <p>${wellnessPlus.physicalExamTwoExamsPerPolicyTerm}</p>
                                                       ${parseInt(wellnessPlus.physicalExamTwoExamsPerPolicyTerm) / 2} max per exam
                                                  </>
                                             )}
                                        </td>
                                        <td>
                                             {wellnessBasic.physicalExamTwoExamsPerPolicyTerm && (
                                                  <>
                                                       <p>${wellnessBasic.physicalExamTwoExamsPerPolicyTerm}</p>
                                                       ${parseInt(wellnessBasic.physicalExamTwoExamsPerPolicyTerm) / 2} max per exam
                                                  </>
                                             )}
                                        </td>
                                   </tr>
                                   <tr>
                                        <td>Behavior exam and/or treatment</td>
                                        <td>${wellnessPlus.behaviorExamAndorTreatment && wellnessPlus.behaviorExamAndorTreatment}</td>
                                        <td>${wellnessBasic.behaviorExamAndorTreatment && wellnessBasic.behaviorExamAndorTreatment}</td>
                                   </tr>
                                   <tr>
                                        <td>Vaccination or titer</td>
                                        <td>${wellnessPlus.vaccinationOrTiter && wellnessPlus.vaccinationOrTiter}</td>
                                        <td>${wellnessBasic.vaccinationOrTiter && wellnessBasic.vaccinationOrTiter}</td>
                                   </tr>
                                   <tr>
                                        <td>Heartworm or FeLV/FIV test</td>
                                        <td>${wellnessPlus.heartwormOrFeLvfivTest && wellnessPlus.heartwormOrFeLvfivTest}</td>
                                        <td>${wellnessBasic.heartwormOrFeLvfivTest && wellnessBasic.heartwormOrFeLvfivTest}</td>
                                   </tr>
                                   <tr>
                                        <td>Fecal test</td>
                                        <td>${wellnessPlus.fecalTest && wellnessPlus.fecalTest}</td>
                                        <td>${wellnessBasic.fecalTest && wellnessBasic.fecalTest}</td>
                                   </tr>
                                   <tr>
                                        <td>Deworming</td>
                                        <td>${wellnessPlus.deworming && wellnessPlus.deworming}</td>
                                        <td>${wellnessBasic.deworming && wellnessBasic.deworming}</td>
                                   </tr>
                                   <tr>
                                        <td>Nail trim</td>
                                        <td>${wellnessPlus.nailTrim && wellnessPlus.nailTrim}</td>
                                        <td>${wellnessBasic.nailTrim && wellnessBasic.nailTrim}</td>
                                   </tr>
                                   <tr>
                                        <td>Microchip</td>
                                        <td>${wellnessPlus.microchip && wellnessPlus.microchip}</td>
                                        <td>${wellnessBasic.microchip && wellnessBasic.microchip}</td>
                                   </tr>
                                   <tr>
                                        <td>Health certificate</td>
                                        <td>${wellnessPlus.healthCertificate && wellnessPlus.healthCertificate}</td>
                                        <td>${wellnessBasic.healthCertificate && wellnessBasic.healthCertificate}</td>
                                   </tr>
                                   <tr>
                                        <td>Flea control or heartworm prevention</td>
                                        <td>${wellnessPlus.fleaControlOrHeartwormPrevention && wellnessPlus.fleaControlOrHeartwormPrevention}</td>
                                        <td>${wellnessBasic.fleaControlOrHeartwormPrevention && wellnessBasic.fleaControlOrHeartwormPrevention}</td>
                                   </tr>
                                   <tr>
                                        <td>
                                             <p>One additional test: <strong>(1)</strong>Health screen (blood test)</p>
                                             <strong>(2)</strong>Radiograph (X-rays) or <strong>(3)</strong>Electrocardiogram (EKG)
                                        </td>
                                        <td>
                                             <p>${wellnessPlus.oneAdditionalTest && wellnessPlus.oneAdditionalTest}</p>
                                             One test per polict term
                                        </td>
                                        <td>
                                             <p>${wellnessBasic.oneAdditionalTest && wellnessBasic.oneAdditionalTest}</p>
                                             One test per polict term
                                        </td>
                                   </tr>
                              </tbody>
                         </table>
                    </div>
               </div>
          </section>
     );
};

export { ContentfulSectionWellnessCoveragePlans }
