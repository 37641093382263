import React from 'react'

const RowsTable = props => {
	var elm = props.arr.map((row, key) => {
		return (
			<tr key={key}>
				{row.map((value, key) => <td key={key}>{value}</td>)}
			</tr>
		)
	})
	return <>{elm}</>
}

const TableNormal = (dataTable) => {
	return (
		<div className={`table-responsive`}>
			<table width="100%" border="0" cellPadding="0" cellSpacing="0">
				<tbody>
					<RowsTable arr={dataTable}></RowsTable>
				</tbody>
			</table>
		</div>
	)
}

const TableScroll = (dataTable) => {
	return (
		<div className="row table-content-article">
			{
				dataTable.dataTable.map((val, i) => {
					return (<div className="col-4" key={i}>
						<h3>{val[0]}</h3>
						<div className="name-list">
							<em>Scroll to see full list</em>
							<ul>
								{
									val.map((a, index) => {
										if (index > 0 && a != '') {
											return <li key={index}>{a}</li>
										}
									})
								}
							</ul>
						</div>
					</div>)
				})
			}
		</div>
	)
}

const TableBackgroundColor = (dataTable) => {
	return (
		<div className="table-content-top-20">
			<h3>{dataTable.dataTable[0]}</h3>
			{
				dataTable.dataTable.map((val, i) => {
					if (i > 0) {
						return (
							<div className="name-list" key={i}>
								<ul>
									{
										val.map((a, index) => {
											if (a != '') {
												return <li key={index}>{a}</li>
											}
										})
									}
								</ul>
							</div>
						)
					}
				})
			}
		</div>
	)
}

const TableHasImage = ({ dataTable, dataTableImage, isPreview }) => {
	return (
		<div className="table-wackiest-pet-names">
			<div className="row">
				{
					dataTable.map((val, i) => {
						const srcImage = isPreview == true ? dataTableImage[i].fields.file.url : dataTableImage[i].fields.file['en-US'].url;
						const altImage = isPreview == true ? dataTableImage[i].fields.title : dataTableImage[i].fields.title['en-US'];
						return (<div className="col-md-6 table-detail" key={i}>
							<h3>{val[0]}</h3>
							<img src={srcImage} alt={altImage} />
							<div className={"name-list name-list-" + i}>
								<ol>
									{
										val.map((a, index) => {
											if (index > 0 && a != '') {
												return <li key={index}>{a}</li>
											}
										})
									}
								</ol>
							</div>
						</div>)
					})
				}
			</div>

		</div>
	)
}

const ContentfulBlockTableScroll = data => {
	var tableType = '';
	var content = '';
	var dataTable = '';
	var dataTableImage = '';
	var isPreview = false;

	if (data.type !== 'ContentfulBlockTable' && typeof data.data.tableScroll['en-US'] != 'undefined') {
		dataTable = data.data.tableScroll['en-US'].tableData;
		if (data.data.hasOwnProperty('tableType')) {
			tableType = data.data.tableType['en-US'];
		}
		if (typeof data.data.tableImages != 'undefined') {
			dataTableImage = data.data.tableImages['en-US'];
		}
	}
	else if (data.type !== 'ContentfulBlockTable' && typeof data.data.tableScroll != 'undefined') {
		dataTable = data.data.tableScroll.tableData;
		tableType = data.data.tableType;
		if (typeof data.data.tableImages != 'undefined') {
			dataTableImage = data.data.tableImages;
			isPreview = true;
		}

	}
	else {
		dataTable = data.tableScroll.tableData;
	}

	switch (tableType) {
		case 'Table Scroll':
			content = (<TableScroll dataTable={dataTable}></TableScroll>)
			break;
		case 'Table Has Background':
			content = (<TableBackgroundColor dataTable={dataTable}></TableBackgroundColor>)
			break;
		case 'Table Has Image':
			content = (<TableHasImage dataTable={dataTable} dataTableImage={dataTableImage} isPreview={isPreview}></TableHasImage>)
			break;
		default:
			content = (<TableNormal dataTable={dataTable}></TableNormal>)
	}
	return (
		<>{content}</>
	)
}

export { ContentfulBlockTableScroll }
