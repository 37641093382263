import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Block from '../block';
import { Image } from '../BlockLibrary/Common/Image';
import { SvgIcon } from '../BlockLibrary/Common/SvgIcon';
import { Link } from 'gatsby';
import { getArticleOrCategorySlugs, getPreviewArticleOrCategorySlugs, addSlashToStr } from '../utilities';
import { getIsPreviewStatus } from '../../state/app';

const MegaMenuDefault = ({ node, categorySlug, menuHover, activeMenuHover }) => {
     return (<>
          <div className="sub-menu mega-col-2">
               {
                    (node.links != null && typeof node.links != 'undefined') && (
                         node.links.map((link, i) => {
                              if (link.subMenu != null) {
                                   var target = link.urlTarget == 'Same Tab' ? '_self' : '_blank';
                                   if (i == 1) {
                                        return (<ul className={`tab-pane ${activeMenuHover =='true' ? 'active' : ''}`} key={i} id={link.contentful_id}>
                                        {
                                             link.subMenu.map((subLink, i) => {
                                                  const url = (subLink.url.charAt(subLink.url.length - 1) == '/') ? subLink.url : (subLink.url + '/');
                                                  return (<li key={i}><Link to={url} target={target}>{subLink.linkText}</Link></li>)
                                             })
                                        }
                                        </ul>)
                                   }
                                   if (i != 0) {
                                        return (<ul
                                             className={`tab-pane ${menuHover == i ? 'active' : ''}`}
                                             key={i}
                                             id={link.contentful_id}
                                        >
                                        {
                                             link.subMenu.map((subLink, i) => {
                                                  const url = (subLink.url.charAt(subLink.url.length - 1) == '/') ? subLink.url : (subLink.url + '/');
                                                  return (<li key={i}><Link to={url} target={target}>{subLink.linkText}</Link></li>)
                                             })
                                        }
                                        </ul>)
                                   }
                                   
                              }
                         })
                    )
               }
          </div>
          {
               (node.featuredArticleInDropDown != null) && (
                    <div className="tab-pane mega-article-content active is-desktop">
                         <a href={categorySlug} tabIndex={-1}><Image image={node.featuredArticleInDropDown.featuredImage} /></a>
                         <h3><Link to={categorySlug}>{node.featuredArticleInDropDown.contentName}</Link></h3>
                         <p>{node.featuredArticleInDropDown.shortDescription}</p>
                         <a href={categorySlug} tabIndex={-1} className="button-featured">Featured</a>
                    </div>
               )
          }
     </>)
}

const MegaMenuPreview = ({ node, categorySlug, menuHover }) => {
     return (<>
          <div className="sub-menu mega-col-2">
               {
                    (node.links != null && typeof node.links != 'undefined') && (
                         node.links.map((link, i) => {
                              if (link.fields.subMenu != null) {
                                   var target = link.fields.urlTarget == 'Same Tab' ? '_self' : '_blank';

                                   return (<ul className={`tab-pane ${menuHover == i ? 'active' : ''}`} key={i} id={link.sys.id}>
                                        {
                                             link.fields.subMenu.map((subLink, i) => {
                                                  const url = (subLink.fields.url.charAt(subLink.fields.url.length - 1) == '/') ? subLink.fields.url : (subLink.fields.url + '/');

                                                  return (<li key={i}><Link to={url} target={target}>{subLink.fields.linkText}</Link></li>)
                                             })
                                        }
                                   </ul>)
                              }
                         })
                    )
               }
          </div>
          {
               (node.featuredArticleInDropDown != null) && (
                    <div className="tab-pane mega-article-content active is-desktop">
                         <a href={categorySlug} tabIndex={-1}><Image image={node.featuredArticleInDropDown.fields.featuredImage} /></a>
                         <h3><Link to={categorySlug}>{node.featuredArticleInDropDown.fields.contentName}</Link></h3>
                         <p>{node.featuredArticleInDropDown.fields.shortDescription}</p>
                         <a href={categorySlug} tabIndex={-1} className="button-featured">Featured</a>
                    </div>
               )
          }
     </>)
}

const MegaMenu = ({ node, categorySlug }) => {
     const [menuHover, setMenuHover] = useState('');
     const [activeMenuHover, setActiveMenuHover] = useState(true)
     const [activeMenu, setActiveMenu] = useState(true)
     return (
          <>
               <div className="is-desktop">
                    <div className="mega-menu" onMouseLeave={()=>setActiveMenu(true)}>
                         <ul className="mega-col-1">
                              {
                                   node.links.map((link, i) => {
                                        var url = null;
                                        var target = link.urlTarget == 'Same Tab' ? '_self' : '_blank';
                                        if (link.url) {
                                             var linkUrl = (link.url.includes('#') || link.url.charAt(link.url.length - 1) == "/") ? link.url : (link.url + '/');
                                             url = (
                                                  <a
                                                       href={linkUrl}
                                                       data-content={node.isPreview == true ? "featured-" + link.sys.id : "featured-" + link.contentful_id}
                                                       data-target={node.isPreview == true ? link.sys.id : link.contentful_id}
                                                       target={target}>{link.linkText}
                                                  </a>
                                             );
                                        }
                                        if (i == 1) {
                                             return (
                                                  <li
                                                       key={i}
                                                       className={`${activeMenu ? 'active' : ''}`}
                                                       onMouseEnter={() => {
                                                            setMenuHover(i)
                                                            setActiveMenuHover(true)
                                                       }}
                                                       onFocus={() => {
                                                            setMenuHover(i)
                                                            setActiveMenuHover(true)
                                                       }}
                                                  >{url}</li>)
                                        }

                                        return (
                                             (i != 0) && (<li key={i} onMouseOver={() => setActiveMenu(false)}
                                                                 onMouseEnter={() => {
                                                                      setMenuHover(i)
                                                                      setActiveMenuHover(false)
                                                                 }}
                                                                 onFocus={() => {
                                                                      setMenuHover(i)
                                                                      setActiveMenuHover(false)
                                                                 }}
                                                            >{url}</li>))
                                   })
                              }
                         </ul>
                         {
                              node.isPreview == true ? (
                                   <MegaMenuPreview node={node} categorySlug={categorySlug} menuHover={`${menuHover}`} activeMenuHover={`${activeMenuHover}`}></MegaMenuPreview>
                              ) : (
                                        <MegaMenuDefault node={node} categorySlug={categorySlug} menuHover={`${menuHover}`} activeMenuHover={`${activeMenuHover}`}></MegaMenuDefault>
                                   )
                         }
                    </div>
               </div>
               <div className="is-mobile">
                    <ul className="focus-menu">
                         {
                              node.links.map((link, i) => {
                                   if (link.subMenu != null) {
                                        var target = link.urlTarget == 'Same Tab' ? '_self' : '_blank';
                                        return (<li key={i} className="has-sub-menu">
                                             <span>
                                                  <Block block={link}></Block>
                                                  <SvgIcon type="down" />
                                             </span>
                                             <ul className="sub-menu">
                                                  {
                                                       link.subMenu.map((val, i) => {
                                                            const url = (val.url.charAt(val.url.length - 1) == '/') ? val.url : (val.url + '/');

                                                            return <li key={i}><Link to={url} target={target}>{val.linkText}</Link></li>
                                                       })
                                                  }
                                             </ul>
                                        </li>)
                                   }
                                   return ((i != 0) && (<li key={i}><Block block={link}></Block></li>))
                              })
                         }
                    </ul>
               </div>
          </>
     )
}


const NavigationDropdown = (node) => {
     var categorySlug = null;
     if (node.featuredArticleInDropDown != null) {
          if (typeof node.featuredArticleInDropDown.category != "undefined") {
               categorySlug = `/healthzone/${getArticleOrCategorySlugs(node.featuredArticleInDropDown.category).reverse().join('/')}/${node.featuredArticleInDropDown.slug}`;
          }
          if (typeof node.featuredArticleInDropDown != "undefined" && node.isPreview == true) {
               categorySlug = `/healthzone/${getPreviewArticleOrCategorySlugs(node.featuredArticleInDropDown.fields.category).reverse().join('/')}/${node.featuredArticleInDropDown.fields.slug}`;
          }

          categorySlug = addSlashToStr(categorySlug);
     }
     if (node.links.length > 1) {
          // console.log(node.links[0])
          return (<>
               <li className={`has-child ${node.isMegaMenu == true ? 'mega-menu-item' : ''}${node.icon != null ? 'menu-has-icon' : ''}`}>
                    {
                         node.isMegaMenu == true ? (
                              <span>
                                   <Block
                                        block={node.links[0]}>
                                   </Block>
                                   <SvgIcon type="down" />
                              </span>
                         ) : (
                                   <>
                                        <Block
                                             block={node.links[0]}></Block>
                                        <SvgIcon type="down" />
                                   </>
                              )
                    }
                    {
                         node.isMegaMenu == true ? (
                              <MegaMenu node={node} categorySlug={categorySlug}></MegaMenu>
                         ) : (
                                   <ul className="focus-menu">
                                        {
                                             node.links.map((link, i) => {
                                                  return ((i != 0) && (<li key={i}><Block block={link}></Block></li>))
                                             })
                                        }
                                   </ul>
                              )
                    }
                    {
                         node.icon != null && (
                              <div className="icon-wrap is-mobile">
                                   <Image image={node.icon} />
                              </div>
                         )
                    }
               </li>
               {
                    node.icon != null && (
                         <li className="desktop-icon-wrap">
                              <div className="icon-wrap">
                                   <Image image={node.icon} />
                              </div>
                         </li>
                    )
               }
          </>
          );
     } else {
          return (
               <li><Block block={node.links[0]}></Block></li>
          )
     }
};

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}
NavigationDropdown.propTypes = {
     isPreview: PropTypes.bool,
}

const ContentfulNavigationDropdown = connect(mapStateToProps)(NavigationDropdown);

export { ContentfulNavigationDropdown }
