import React, { Component } from 'react'
import Block from '../block'

class ContentfulBlockDisplayASeries extends Component {
     constructor(props) {
          super(props);

          this.state = {
               itemCounting: 3
          }
     }

     seeMoreReviews = (e) => {
          e.preventDefault();

          const itemPerPage = 3;

          this.setState({ itemCounting: this.state.itemCounting + itemPerPage });
     }

     render() {
          const totalItems = this.props.seriesOfContentToDisplay.length;
          const itemArr = this.props.seriesOfContentToDisplay.slice(0, this.state.itemCounting);
          return (
               <>
                    <section className="customer-reviews">
                         <div className="container">
                              <div className="review-rows">
                                   <div className="row">
                                        {
                                             itemArr.map((item, i) => (
                                                  <div className="col-lg-4 col-sm-6 review" key={i}>
                                                       <Block template={'list'} block={item} />
                                                  </div>))
                                        }
                                   </div>
                              </div>
                              <div className={"button-container" + (this.state.itemCounting >= totalItems ? ' d-none' : '')}>
                                   <Block onClick={this.seeMoreReviews} block={this.props.buttonBelowSeriesOfContent} />
                              </div>
                         </div>
                    </section>
               </>
          )
     }
}

export { ContentfulBlockDisplayASeries }
