import React from 'react';
import Block from '../block';
import Accordion from 'react-bootstrap/Accordion';
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { connect } from "react-redux";

class SectionConditionCoverage extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               chartIndex: 0,
               sampleCondition: '',
               accordionId: this.props.layout == 'Simple Table' ? (this.props.isPreview==true ? this.props.conditionToShow[0].sys.id : this.props.conditionToShow[0].id) : null
          }
     }

     onConditionButtonPress = (index) => {
          this.setState({ ...this.state, chartIndex: index })
     }

     onInputSearchChanged = (e) => {
          this.setState({ ...this.state, sampleCondition: e.target.value })
     }
     setAccordionId = (id) => {
          this.setState({
               accordionId: id
               })
          } 
     render() {
          const { layout, conditionToShow, blockContainer, isPreview } = this.props;
          if (layout == 'Simple Table') {
               var mobileSampleConditions = conditionToShow.filter((condition) => {
                    var conditionObj = this.props.isPreview==true ? condition.fields : condition;
                    return conditionObj.conditionName.toLowerCase().includes(this.state.sampleCondition.toLowerCase())
               })
          }
          return (
               <>
                    {
                         layout == 'Coverage Detail View with Charts' ? (
                              <section className={`coverage-charts desktop`}>
                                   <div className="container">
                                        <ul className="menu-button">
                                             {
                                                  conditionToShow.map((node, i) => {
                                                       var nodeConditionName = isPreview == true ? node.fields.conditionName : node.conditionName;
                                                       return (
                                                            <li tabIndex="0" className={this.state.chartIndex == i ? 'choosed' : ''} onClick={() => this.onConditionButtonPress(i, node.id)}  onKeyDown={(e) => { if (e.keyCode === 13) { this.onConditionButtonPress(i, node.id) } }} key={i}>
                                                                 {nodeConditionName && nodeConditionName}
                                                            </li>
                                                       )
                                                  })
                                             }
                                        </ul>
                                        {
                                             conditionToShow.map((node, i) => (
                                                  (this.state.chartIndex == i) && (<Block block={node} key={i} />)
                                             ))
                                        }
                                        {<Block block={blockContainer} />}
                                   </div>
                              </section>
                         ) : (
                                   <section className={`coverage-table`}>
                                        <div className="covered-desktop desktop">
                                             <div className="container">
                                                  <h2>Sample conditions</h2>
                                                  <div className="content-wrapper">
                                                       <table className="table">
                                                            <thead>
                                                                 <tr>
                                                                      <th></th>
                                                                      <th>
                                                                           <div className="wholePet">
                                                                                <img src="/images/wholepet.png" alt='wholepet' />
                                                                           </div>
                                                                      </th>
                                                                      <th>
                                                                           <div className="majorMedical">
                                                                                <img src="/images/major-medical.png" alt='major-medical' />
                                                                           </div>
                                                                      </th>
                                                                      <th>
                                                                           <div className="wellnessPlus">
                                                                                <img src="/images/petwellness.png" alt='petwellness' />
                                                                           </div>
                                                                      </th>
                                                                 </tr>
                                                            </thead>
                                                            <tbody tabIndex="0">
                                                                 {conditionToShow.map((node, i) => (
                                                                      <Block template='desktop' block={node} key={i} />
                                                                 ))}
                                                            </tbody>
                                                       </table>
                                                  </div>
                                                  <div className="table-description">
                                                       <div className="legend-description">
                                                            <div className="full"></div>
                                                            <p> Full coverage</p>
                                                       </div>
                                                       <div className="legend-description">
                                                            <div className="half-gradient"></div>
                                                            <p> Some coverage</p>
                                                       </div>
                                                  </div>
                                                  {<Block block={blockContainer} />}
                                             </div>
                                        </div>
                                        <div className="covered-mobile mobile">
                                             <div className="container">
                                                  <p className="whats-covered-top">Coverage level:</p>
                                                  <div className="row">
                                                       <div className="col-4 legend-description">
                                                            <div className="full"></div>
                                                            <p>Full</p>
                                                       </div>
                                                       <div className="col-4 legend-description">
                                                            <div className="half-gradient"></div>
                                                            <p>Some</p>
                                                       </div>
                                                       <div className="col-4 legend-description">
                                                            <div className="empty"></div>
                                                            <p>None</p>
                                                       </div>
                                                  </div>
                                                  <div className="pet-search">
                                                       <input
                                                            type="text"
                                                            id="search-box"
                                                            title="Search"
                                                            placeholder="Search sample conditions"
                                                            onChange={this.onInputSearchChanged}
                                                       />
                                                       <ul className="pet-search-list">
                                                            <div className="pet-accordions">
                                                                 <Accordion defaultActiveKey={this.state.sampleCondition ? null : mobileSampleConditions[0].id}>
                                                                      {mobileSampleConditions.map((condition, i) => {
                                                                           const conditionId = isPreview == true ? condition.sys.id : condition.id;
                                                                           return (this.state.accordionId === conditionId ? (
                                                                                <li key={i} className="active">
                                                                                     <Block template='mobile' block={condition} onClick={() => this.setAccordionId('')} />
                                                                                </li>
                                                                           ) : (
                                                                                     <li key={i}>
                                                                                          <Block template='mobile' block={condition} onClick={() => this.setAccordionId(conditionId)} />
                                                                                     </li>
                                                                                )
                                                                           )
                                                                      }
                                                                      )}
                                                                 </Accordion>
                                                            </div>
                                                       </ul>
                                                       <div className="covered-cant-find">
                                                            <p>Can't find what you're looking for?</p>
                                                            <a href="#" aria-label="View">View full coverage details.</a>
                                                       </div>
                                                       {<Block block={blockContainer} />}
                                                  </div>
                                             </div>
                                        </div>
                                   </section>
                              )
                    }
               </>
          );
     }
};


function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state),
     };
}

SectionConditionCoverage.propTypes = {
     isPreview: PropTypes.bool,
}

const ContentfulSectionConditionCoverage = connect(mapStateToProps)(SectionConditionCoverage);

export { ContentfulSectionConditionCoverage }
