import React from 'react'
import Block from '../block';
import { Image } from '../BlockLibrary/Common/Image';
import { RichText } from '../richTextOptions';

const ContentfulSectionCoverageForAnimals = (coverage) => {
     const {
          coverageFor,
          eligibleTitle,
          leftEligibleList,
          rightEligibleList,
          eligibleImage,
          coverageDetailTitle,
          firstCoverageDetailImage,
          coverageDetailRows,
          phoneNumber,
          sectionId
     } = coverage;

     return (
          <section className={`eligible-species`} id={sectionId.trim()}>
               <div className="blue-bar">
                    <div className="container">
                         {coverageFor && <h2>Coverage for {coverageFor}</h2>}
                    </div>
               </div>
               <div className="container">
                    <div className="content-wrapper">
                         {eligibleTitle && <h3>{eligibleTitle}</h3>}
                         <div className="row">
                              <div className="col-6 col-md-4">
                                   {leftEligibleList && <RichText data={leftEligibleList}></RichText>}
                              </div>
                              <div className="col-6 col-md-4">
                                   {rightEligibleList && <RichText data={rightEligibleList}></RichText>}
                              </div>
                              <div className="text-center col-md-4">
                                   {eligibleImage && <Image image={eligibleImage} />}
                              </div>
                         </div>
                         <hr style={{ borderTop: "1px solid rgba(19, 18, 18, 0.445)" }}></hr>
                         {coverageDetailTitle && <h3 className="top-10-header">{coverageDetailTitle}</h3>}
                         {firstCoverageDetailImage && <Image image={firstCoverageDetailImage} cla="graph-img" />}
                         {coverageDetailRows && coverageDetailRows.map((row, i) => (
                              <div className="condition-row" key={i}>
                                   <Block block={row} />
                              </div>
                         ))}
                         {
                              phoneNumber && (
                                   <div className="coverage-phone-number">
                                        <h2>Call for a quote <a href={`tel:1-${phoneNumber}`}>{phoneNumber}</a></h2>
                                   </div>
                              )
                         }
                    </div>
               </div>
          </section>
     )
}

export { ContentfulSectionCoverageForAnimals }
