import React from 'react';
import PropTypes from "prop-types";
import { getStateFiles } from "../Hooks/StateFiles";
import { getIsPreviewStatus } from '../../state/app';
import { connect } from "react-redux";

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state),
          statesFile: state.app.statesFile,
          stateSelected: state.app.stateSelected
     };
}

const DownloadButton = (text) => {
     const stateSelected = text.stateSelected;
     const statesFile = getStateFiles();
     const handleOnclick = () => {
          if (stateSelected != '' && stateSelected != null && !['Hide', 'All', 'None'].includes(stateSelected)) {
               statesFile.map((file) => {
                    var state = file.node.state[0];
                    if (state.stateAbbreviation == stateSelected) {
                         window.open(file.node.formFiles[0].file.url, '_blank');
                         // window.location.href = file.node.formFiles[0].file.url;
                    }
               })
          } else {
               alert('Please choose a state for download claim file');
          }
     }
     return (
          <>
               <input type="button" value="Download form" className="get-quote-button-white download-form" onClick={handleOnclick} />
          </>
     )
}

DownloadButton.propTypes = {
     isPreview: PropTypes.bool,
     stateSelected: PropTypes.string,
     statesFile: PropTypes.array,
}

const ContentfulDownloadButton = connect(mapStateToProps)(DownloadButton);

export { ContentfulDownloadButton }
