import React from 'react'
import Block from '../block'
import { RichText } from '../richTextOptions'

const ContentfulSectionReviewsGrid = blockData => {
     return (
          <section className={`cat-plan-bottom`}>
               <div className="container">
                    {blockData.headline && <RichText data={blockData.headline}></RichText>}
                    <div className="storyDetail">
                         {blockData.textBelowHeadline && <RichText data={blockData.textBelowHeadline}></RichText>}
                         <div className="ratting">
                              <div className="star-rattings">
                                   <img
                                        src="/images/cat-insurance/star-ratting.png"
                                        alt="star-ratting"
                                   />
                                   <img
                                        src="/images/cat-insurance/star-ratting.png"
                                        alt="star-ratting"
                                   />
                                   <img
                                        src="/images/cat-insurance/star-ratting.png"
                                        alt="star-ratting"
                                   />
                                   <img
                                        src="/images/cat-insurance/star-ratting.png"
                                        alt="star-ratting"
                                   />
                                   <img
                                        src="/images/cat-insurance/star-not-ratting.png"
                                        alt="star-not-ratting"
                                   />
                              </div>
                              {blockData.overallStarRating && (
                                   <span className="score">{blockData.overallStarRating}</span>
                              )}
                         </div>
                    </div>
                    {blockData.reviewsToDisplay && (
                         <div className="row">
                              {blockData.reviewsToDisplay.map((reviewData, i) => {
                                   return (
                                        <div className="col-lg-4" key={i}>
                                             <div className="review">
                                                  <Block block={reviewData} key={i}></Block>
                                             </div>
                                        </div>
                                   )
                              })}
                         </div>
                    )}
               </div>
          </section>
     )
}

export { ContentfulSectionReviewsGrid }
