import React from 'react';
import Block from '../block';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

const ContentfulSectionTestimonialSlider = (section) => {
     const settings = {
          dots: false,
          arrows: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2.999999,
          centerMode: true,
          centerPadding: 0,
          // responsive: [
          //      {
          //           breakpoint: 921,
          //           settings: {
          //                slidesToShow: 1,
          //                arrows: false,
          //                dots: true,
          //           }
          //      },
          // ],
     };
     return (
          <section className={`slider`}>
               <div className="testimonial-desktop testimonial-slider">
                    <Slider {...settings}>
                         {section.testimonials && (
                              section.testimonials.map((block, i) => {
                                   return (<Block block={block} key={i}></Block>)
                              })
                         )}
                    </Slider>
               </div>
               <div className="testimonial-mobile testimonial-slider">
                    <Slider infinite={true} speed={500} centerMode={true} centerPadding={0} dots={true} slidesToShow={1} arrows={false}>
                         {section.testimonials && (
                              section.testimonials.map((block, i) => {
                                   return (<Block block={block} key={i}></Block>)
                              })
                         )}
                    </Slider>
               </div>
          </section>
     )
}

export { ContentfulSectionTestimonialSlider }
