import React from 'react'
import { Image } from '../BlockLibrary/Common/Image'

const ContentfulBlockIconWithText = block => {
     return (
          <>
               {(block.h3Text && block.normalText) ? (
                    block.icon && (
                         <>
                              <div className="icon-container">
                                   <Image image={block.icon} />
                              </div>
                              <div className="text-container">
                                   <h3>{block.h3Text}</h3>
                                   <p>{block.normalText}</p>
                              </div>
                         </>
                    )
               ) : (
                         <div className="icon-with-text">
                              {block.icon && <Image image={block.icon} />}
                              {block.h3Text && <h3>{block.h3Text}</h3>}
                              {block.normalText && <p>{block.normalText}</p>}
                         </div>
                    )}
          </>
     )
}

export { ContentfulBlockIconWithText }