import React from 'react'

const SvgIconDown = () => {
    return (
        <svg className="bi bi-chevron-down" tabIndex="-1" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" tabIndex="-1" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
        </svg>
    )
}

const SvgIconLeft = (bold) => {
    return (<>
        {
            bold ? (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" className="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>) :
                (<svg className="bi bi-chevron-left" tabIndex="-1" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" tabIndex="-1" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" /></svg>)
        }</>
    )
}

const SvgIconRight = (bold) => {
    return (<>
        {
            bold ? (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>) :
                (<svg className="bi bi-chevron-right" tabIndex="-1" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" tabIndex="-1" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" /></svg>)
        }
    </>)
}

const SvgCallAnswer = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.462,18.983l-3.706-3.715a1.929,1.929,0,0,0-2.725.051L15.163,17.19l-.368-.205A18.583,18.583,0,0,1,10.3,13.729a18.728,18.728,0,0,1-3.255-4.51c-.069-.125-.135-.246-.2-.361L8.1,7.6l.616-.618a1.938,1.938,0,0,0,.05-2.731L5.06.539A1.927,1.927,0,0,0,2.336.59L1.291,1.643l.029.028A6.058,6.058,0,0,0,.459,3.194,6.321,6.321,0,0,0,.076,4.737C-.414,8.8,1.44,12.52,6.472,17.563c6.955,6.971,12.56,6.444,12.8,6.418a6.251,6.251,0,0,0,1.544-.389,6.017,6.017,0,0,0,1.513-.859l.023.02,1.058-1.039A1.942,1.942,0,0,0,23.462,18.983Z" fill="#767676" /></svg>)
}

const SvgSearch = () => {
    return (
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"></path></svg>
    )
}

const SvgIcon = (type) => {
    var content = '';
    var bold = type.bold ? type.bold : false;
    switch (type.type) {
        case 'right':
            content = <SvgIconRight bold={bold} />
            break;
        case 'left':
            content = <SvgIconLeft bold={bold} />
            break;
        case 'phone':
            content = <SvgCallAnswer />
            break;
        case 'search':
            content = <SvgSearch />
            break;
        default:
            content = <SvgIconDown bold={bold} />
            break;
    }
    return (<>{content}</>)
}

export { SvgIcon };
