import React from 'react'
import { RichText } from '../richTextOptions';
import { Image } from '../BlockLibrary/Common/Image';

const ContentfulSectionCustomerStory = (customerStory) => {
	var sectionClass = '';

	switch (customerStory.separator) {
		case 'Thin Line':
			sectionClass = 'thin-line';
			break;
		default:
			sectionClass = '';
	}

	switch (customerStory.visualStyle) {
		case 'Image Left':
			sectionClass += ' image-left';
			break;
		case 'Image Right':
			sectionClass += ' image-right';
			break;
	}

	return (
		<section className={`customer-story ${sectionClass}`} >
			<div className="container">
				<div className="row">
					{customerStory.visualStyle == 'Image Left' && (
						<div className="col-lg-6 customer-story-img-left">
							<div className="image">
								{customerStory.image && (
									<Image image={customerStory.image} />
								)}
								{customerStory.amountSaved && (<div className="amount-saved"><label>Saved</label> {customerStory.amountSaved}</div>)}
							</div>
						</div>
					)}
					<div className="col-lg-6 customer-story-content">
						<div className="story-content">
							{
								customerStory.imageAboveH1 && (
									<Image image={customerStory.imageAboveH1} />
								)
							}
							{
								customerStory.storyHeadline && <h2>{customerStory.storyHeadline}</h2>
							}
							{
								customerStory.storyText && (
									<RichText data={customerStory.storyText}></RichText>
								)
							}
							{
								customerStory.ctaButton && <Block block={customerStory.ctaButton} />
							}
						</div>
					</div>
					{customerStory.visualStyle == 'Image Right' && (
						<div className="col-lg-6 customer-story-img-right">
							<div className="image">
								{customerStory.image && (
									<Image image={customerStory.image} />
								)}
								{customerStory.amountSaved && (<div className="amount-saved"><label>Saved</label> {customerStory.amountSaved}</div>)}
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	)
}

export { ContentfulSectionCustomerStory }
