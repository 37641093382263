import React from 'react';
import { RichText } from '../richTextOptions';
import { Image } from '../BlockLibrary/Common/Image';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

const BlockResources = (resources) => {
     return (
          <div className={`resources-block`}>
               {
                    resources.resources.map((resource, i) => {
                         resource = resources.isPreview ? resource.fields : resource;
                         return (
                              <div className="resource-item" key={i}>
                                   <div className="thumbnail">
                                        <Image image={resource.optionalIcon} />
                                   </div>
                                   <div className="content">
                                        <RichText data={resource.richText}></RichText>
                                   </div>
                              </div>
                         )
                    })
               }
          </div>
     )
}

BlockResources.propTypes = {
     isPreview: PropTypes.bool,
}

const ContentfulBlockResources = connect(mapStateToProps)(BlockResources);

export { ContentfulBlockResources }
