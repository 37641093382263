import React from 'react';
import { connect } from 'react-redux';
import DefaultHeader from './DefaultHeader';
import DefaultHeaderHz from './DefaultHeaderHz';
import DefaultHeaderPg from './DefaultHeaderPG';
import DefaultHeaderWacky from './DefaultHeaderWacky';
import DefaultHeaderVideo from './DefaultHeaderVideo';
import DefaultHeaderCurePet from './DefaultHeaderCurePet';
import DefaultHeaderUC from './DefaultHeaderUC';
import DefaultHeaderMenuMobile from './DefaultHeaderMenuMobile';
import DefaultHeaderOnlyALogo from './DefaultHeaderOnlyALogo';
import PropTypes from 'prop-types';
import { getIsPreviewStatus } from '../../state/app';

const PageHeader = ({ header, isPreview, specificPhone }) => {
     if (header == null) { return <></> }
     var content = '';

     switch (isPreview ? header.fields.layout : header.layout) {
          case 'WackyPetNames':
               content = <DefaultHeaderWacky header={header}></DefaultHeaderWacky>
               break;
          case 'PetGazette':
               content = <DefaultHeaderPg header={header}></DefaultHeaderPg>
               break;
          case 'HZ':
               content = <DefaultHeaderHz header={header} specificPhone={specificPhone} ></DefaultHeaderHz>
               break;
          case 'Video':
               content = <DefaultHeaderVideo header={header} specificPhone={specificPhone} ></DefaultHeaderVideo>
               break;
          case 'CurePetCancer':
               content = <DefaultHeaderCurePet header={header} specificPhone={specificPhone} ></DefaultHeaderCurePet>
               break;
          case 'UC':
               content = <DefaultHeaderUC header={header} ></DefaultHeaderUC>
               break;
          case 'MenuMobile':
               content = <DefaultHeaderMenuMobile header={header} specificPhone={specificPhone} ></DefaultHeaderMenuMobile>
               break;
          case 'OnlyALogo':
               content = <DefaultHeaderOnlyALogo header={header}></DefaultHeaderOnlyALogo>
               break;
          default:
               content = <DefaultHeader header={header} specificPhone={specificPhone} ></DefaultHeader>
               break;
     }
     return (
          <header>
               {content}
          </header>
     )
}

const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

PageHeader.propTypes = {
     isPreview: PropTypes.bool,
}

const Header = connect(mapStateToProps)(PageHeader);

export default Header;
