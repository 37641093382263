import React from 'react';
import Block from '../block';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

const NavigationMenu = ({ menuItem, classItem, isPreview }) => {
     if (isPreview == true) {
          menuItem = menuItem.fields;
     }
     return (
          <>
               {
                    menuItem.dropdownsOrLinks.map((node, i) => {
                         let type = null;
                         if (typeof node.fields !== 'undefined') {
                              type = 'Contentful' + node.sys.contentType.sys.id.charAt(0).toUpperCase() + node.sys.contentType.sys.id.slice(1);
                         } else {
                              type = node.type;
                         }
                         if (type == 'ContentfulNavigationDropdown') {
                              return (
                                   <Block key={i} block={node} />
                              )
                         } else {
                              return ((<li key={i} className={classItem}><Block block={node} /></li>))
                         }
                    })
               }
          </>
     );
};


NavigationMenu.propTypes = {
     isPreview: PropTypes.bool,
}
const ContentfulNavigationMenu = connect(mapStateToProps)(NavigationMenu);

export { ContentfulNavigationMenu }