import React from 'react'
import Block from '../block';

const ContentfulBlockListContent = listContent => {
     return (
          <ul>
               {listContent.itemList && listContent.itemList.map((item, i) => {
                    return (
                         <li key={i}>
                              <Block block={item} />
                         </li>
                    )
               })}
          </ul>
     )
}

export { ContentfulBlockListContent }
