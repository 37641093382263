import React from 'react'
import Block from '../block';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { RichText } from '../richTextOptions';
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { connect } from "react-redux";

const BlockVetsTab = (tab) => {
    return (
        <>
            {tab.headerDescription && <RichText data={tab.headerDescription} />}
            {
                (tab !== null) && (
                    <Tabs className="tab-content-vets">
                        <TabList className="tab-title">
                            {
                                tab.tabItems.map((val, i) => {
                                    const title = tab.isPreview ? val.fields.contentName : val.contentName;
                                    return <Tab tabIndex="0" key={i} className={"tab-title-" + i}>{title}</Tab>
                                })
                            }
                        </TabList>
                        {
                            tab.tabItems.map((val, i) => {
                                return (
                                    <TabPanel key={i} className={`tab-content tab-content-${i + 1}`}>
                                        <Block block={val}></Block>
                                    </TabPanel>
                                )
                            })
                        }
                    </Tabs>
                )
            }
        </>
    )
}

BlockVetsTab.propTypes = {
    isPreview: PropTypes.bool,
}

const mapStateToProps = (state) => {
    return {
        isPreview: getIsPreviewStatus(state),
    };
}

const ContentfulBlockVetsTab = connect(mapStateToProps)(BlockVetsTab);

export { ContentfulBlockVetsTab };
