import { useStaticQuery, graphql } from "gatsby";
export const getStates = () => {
     const { allContentfulStates } = useStaticQuery(
          graphql`
               query {
                    allContentfulStates(sort: {fields: order, order: ASC}) {
                         edges {
                              node {
                                   id
                                   stateName
                                   stateAbbreviation
                                   contentful_id
                              }
                         }
                    }
               }
          `
     )
     return allContentfulStates.edges;
}