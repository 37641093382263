import React from 'react'
import Block from '../block'
import { RichText } from '../richTextOptions'
import { Image } from '../BlockLibrary/Common/Image';

const ContentfulBlockCta = cta => {
  return (
    <>
      {
        cta.topImage && (
          <div className="image-wrap">
            <Image image={cta.topImage} />
          </div>
        )
      }
      {cta.shortDescription && (
        <RichText data={cta.shortDescription}></RichText>
      )}
      <div className="cta-button-center">
        {cta.ctaButton && <Block block={cta.ctaButton}></Block>}
      </div>
    </>
  )
}

export { ContentfulBlockCta }
