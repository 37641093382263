import React, { useState } from 'react';
import PropTypes from "prop-types";
import { getStates } from "../Hooks/States";
import { getIsPreviewStatus } from '../../state/app';
import { connect } from "react-redux";

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state),
          states: state.app.states,
          stateSelected: state.app.stateSelected
     };
}

const mapDispatchToProps = dispatch => {
     return {
          handleChange: event => {
               dispatch({
                    type: 'SET_STATECHANGE',
                    payload: event.target.value || '',
               });
          }
     }
}

const SectionDisplayForm = ({ handleChange, stateSelected, ariaLabel }) => {
     const [isOpen, setIsOpen] = useState(false);
     const states = getStates();

     const toggleStateDropdown = () => {
          setIsOpen(!isOpen);
     }

     const closeStateDropdown = () => {
          setIsOpen(false);
     }

     if (typeof ariaLabel == 'undefined') {
          ariaLabel = 'Claim form & benefit schedules';
     }

     return (
          <>
               {
                    states && (
                         <>
                              <select aria-label={ariaLabel} className={`form-control ${isOpen ? 'open' : ''}`} defaultValue={stateSelected} onChange={handleChange} onClick={toggleStateDropdown} onBlur={closeStateDropdown}>
                                   {
                                        states.map((state, i) => {
                                             return (
                                                  <option value={state.node.stateAbbreviation} key={i}>{state.node.stateName}</option>
                                             )
                                        })
                                   }
                              </select>
                         </>
                    )
               }
          </>
     )
}

SectionDisplayForm.propTypes = {
     isPreview: PropTypes.bool,
     ariaLable: PropTypes.string,
     states: PropTypes.array,
     stateSelected: PropTypes.string,
     handleChange: PropTypes.func,
     setStageDropdown: PropTypes.func,
}

const ContentfulSectionDisplayForm = connect(mapStateToProps, mapDispatchToProps)(SectionDisplayForm);


export { ContentfulSectionDisplayForm }
