import React, { Component } from 'react'
import Block from '../block'
import { RichText, HtmlCode } from '../richTextOptions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getIsPreviewStatus } from '../../state/app'

function mapStateToProps(state) {
  return {
    isPreview: getIsPreviewStatus(state),
  }
}

const SectionHomeBanner = banner => {
  var bgStyle = {
    backgroundImage:
      'url(' +
      (banner.isPreview
        ? banner.bannerBackground.fields.file.url
        : banner.bannerBackground.file.url) +
      ')',
  }
  return (
    <>
      <section
        className={`section-banner`}
      >
        <div className="filter is-mobile"></div>
        <div className="background-wrap" style={bgStyle}>
          <div className="container">
            {banner.isPreview ? (
              <>
                {banner.callToActionBox &&
                  banner.callToActionBox.sys.contentType.sys.id ===
                    'blockText' && (
                    <div className="row">
                      <div className="col-md-10 col-lg-6">
                        <div className="banner-text">
                          {banner.callToActionBox && (
                            <Block block={banner.callToActionBox}></Block>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                {banner.callToActionBox &&
                  banner.callToActionBox.sys.contentType.sys.id ===
                    'blockCta' && (
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="cta">
                          {banner.callToActionBox && (
                            <Block block={banner.callToActionBox}></Block>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="companion">
                          {banner.subHeadline && banner.subHeadline && (
                            <RichText data={banner.subHeadline}></RichText>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
              </>
            ) : (
              <>
                {banner.callToActionBox &&
                  banner.callToActionBox.__typename ===
                    'ContentfulBlockText' && (
                    <div className="row">
                      <div className="col-md-10 col-lg-6">
                        <div className="banner-text">
                          {banner.callToActionBox && (
                            <Block block={banner.callToActionBox}></Block>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                {banner.callToActionBox &&
                  banner.callToActionBox.__typename ===
                    'ContentfulBlockCta' && (
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="cta">
                          {banner.callToActionBox && (
                            <Block block={banner.callToActionBox}></Block>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="companion">
                          {banner.subHeadline && banner.subHeadline && (
                            <RichText data={banner.subHeadline}></RichText>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </section>
      {banner.bottomText && (
        <section
          className={`section-banner-search ${banner.visualStyleBannerBottomText == 'Only Show Mobile' ? 'show-bottom-text-mobile' : ''}`}
        >
          {banner.isPreview ? (
            <HtmlCode className="container" data={banner.bottomText}></HtmlCode>
          ) : (
            <HtmlCode className="container" data={banner.bottomText}></HtmlCode>
          )}
        </section>
      )}
    </>
  )
}

SectionHomeBanner.propTypes = {
  isPreview: PropTypes.bool,
}

const ContentfulSectionHomeBanner = connect(mapStateToProps)(SectionHomeBanner)

export { ContentfulSectionHomeBanner }
