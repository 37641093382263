import React from 'react';
import Block from '../block';

import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { connect } from "react-redux";


function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state),
          states: state.app.states,
          stateSelected: state.app.stateSelected
     };
}

const SectionStateForms = ({ isPreview, stateSelected, stateForm, state, formsForState }) => {
     const s = isPreview ? state.fields : stateForm;
     return (
          <>
               {
                    s.stateAbbreviation == stateSelected && (
                         <section className={`download-claim`}>
                              <div className="container">
                                   {
                                        formsForState && (
                                             formsForState.map((form, i) => {
                                                  return (<Block block={form} key={i}></Block>)
                                             })
                                        )
                                   }
                              </div>
                         </section>
                    )
               }
          </>
     )
}

SectionStateForms.propTypes = {
     isPreview: PropTypes.bool,
     states: PropTypes.array,
     stateSelected: PropTypes.string,
}

const ContentfulSectionStateForms = connect(mapStateToProps)(SectionStateForms);

export { ContentfulSectionStateForms }
