import React from 'react'
import Block from '../block';
import { RichText } from '../richTextOptions';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';

const SectionLandingCoverageHighlights = (coverageHighlights) => {

     const backgroundImageUrl = coverageHighlights.isPreview ? (coverageHighlights.backgroundImage ? 'url(' + coverageHighlights.backgroundImage.fields.file.url + ')' : null) : (coverageHighlights.backgroundImage ? 'url(' + coverageHighlights.backgroundImage.file.url + ')' : null);

     return (
          <section className={`coverage-highlights ${coverageHighlights.layout == "Circle Left" ? 'circle-left' : 'circle-right'}`} style={{ backgroundImage: backgroundImageUrl }}>
               <div className="container">
                    <div className="row">
                         {
                              (coverageHighlights.layout == "Circle Left") && (
                                   <div className="col-md-5">
                                        <div className="blue-circle">
                                             {coverageHighlights.circleText && <p>{coverageHighlights.circleText}</p>}
                                        </div>
                                        <div className="customer">
                                             {coverageHighlights.underCircleText && <p>{coverageHighlights.underCircleText}</p>}
                                        </div>
                                   </div>
                              )
                         }
                         <div className="col-md-7">
                              <div className="content">
                                   {coverageHighlights.bodyText && <RichText data={coverageHighlights.bodyText}></RichText>}
                                   {coverageHighlights.ctaButton && <Block block={coverageHighlights.ctaButton} />}
                              </div>
                         </div>
                         {
                              (coverageHighlights.layout == "Circle Right") && (
                                   <div className="col-md-5">
                                        <div className="content-right">
                                             <div className="blue-circle">
                                                  {coverageHighlights.circleText && <p>{coverageHighlights.circleText}</p>}
                                             </div>
                                             <div className="customer">
                                                  {coverageHighlights.underCircleText && <p>{coverageHighlights.underCircleText}</p>}
                                             </div>
                                        </div>
                                   </div>
                              )
                         }
                    </div>
               </div>
          </section>
     )
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

SectionLandingCoverageHighlights.propTypes = {
     isPreview: PropTypes.bool,
}
const ContentfulSectionLandingCoverageHighlights = connect(mapStateToProps)(SectionLandingCoverageHighlights);

export { ContentfulSectionLandingCoverageHighlights }
