import React from 'react'

const ContentfulBlockCoverageForAnimals = (coverage) => {
     return (
          <div className="condition">
               <h3>
                    {coverage.ordinalNumber && <span className="ordinal-number">{coverage.ordinalNumber}.</span>}
                    {coverage.title && coverage.title}
               </h3>
               <p>Average cost:
                    {coverage.averageCost && <strong className="average-cost"> ${coverage.averageCost}</strong>}
               </p>
               <p>Nationwide member net cost:
                    {coverage.netCost && <strong className="net-cost"> ${coverage.netCost}</strong>}
               </p>
          </div>
     )
}

export { ContentfulBlockCoverageForAnimals }
