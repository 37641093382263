import React from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../../state/app';

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

const ContentfulImage = ({ image, isPreview, cla, altOverride }) => {
     if (typeof image != 'undefined' && image != null) {
          if (isPreview == true) {
               image = image.fields;
          }

          let altImage = '';
          let imgTitle = '';
          if (image) {
               imgTitle = image.title;
               imgTitle = imgTitle.replace(/\s[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '')
               imgTitle = imgTitle.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '').trim()
          }

          if (altOverride) {
               altImage = altOverride;
          }
          else {
               (image.description === '' || image.description === null) ? altImage = imgTitle : altImage = image.description
          }
          return (
               <>
                    {
                         image && image.file && (
                              <img
                                   className={cla}
                                   src={image.file.url}
                                   alt={altImage}

                              />
                         )
                    }
               </>
          )
     } else {
          return <></>
     }

}
ContentfulImage.propTypes = {
     isPreview: PropTypes.bool,
}
const Image = connect(mapStateToProps)(ContentfulImage);

export { Image }
