import React from 'react'
import { Image } from '../BlockLibrary/Common/Image'
import { connect } from "react-redux";
import { changeTrackingCode } from '../BlockLibrary/Common/changeTrackingCode'
import { getIsPreviewStatus, getTrackingCode, getTrackingPhone } from '../../state/app';
import { addSlashToStr } from '../utilities';
import { SvgIcon } from '../BlockLibrary/Common/SvgIcon';

const NavigationButton = (button) => {
	var url = null;
	var target = button.urlTarget == 'Same Tab' ? '_self' : '_blank';
	if (typeof button.buttonRichText != 'undefined') {
		if (!button.isPreview) {
			let buttonUrl = '';
			if (button.buttonRichText.urlToUse['en-US'] == 'External URL') {
				buttonUrl = (button.buttonRichText.externalUrl['en-US'] != 'undefined') ? button.buttonRichText.externalUrl['en-US'] : '';
			} else {
				buttonUrl = (button.buttonRichText.internalUrl['en-US'] != 'undefined') ? button.buttonRichText.internalUrl['en-US'] : '';
				buttonUrl = addSlashToStr(buttonUrl);
			}

			if (buttonUrl) {
				// Navigation Button in Richtext
				let buttonRichText = button.buttonRichText
				target = buttonRichText.urlTarget['en-US'] == 'Same Tab' ? '_self' : '_blank';
				url = (
					<div className={buttonRichText.buttonClass['en-US']}>
						<a
							href={buttonUrl}
							target={target}
						>
							{buttonRichText.buttonText['en-US']}
						</a>
						{
							(buttonRichText.textInline && buttonRichText.textInline['en-US']) && (
								<div className="text-inline">
									<p>{buttonRichText.textInline['en-US']}</p>
								</div>)
						}
					</div>
				)
			}
		} else {
			// Preview Navigation Button in Richtext
			let buttonUrl = '';
			if (button.buttonRichText.urlToUse == 'External URL') {
				buttonUrl = (button.buttonRichText.externalUrl != 'undefined') ? button.buttonRichText.externalUrl : '';
			} else {
				buttonUrl = (button.buttonRichText.internalUrl != 'undefined') ? button.buttonRichText.internalUrl : '';
				buttonUrl = addSlashToStr(buttonUrl);
			}

			if (buttonUrl) {
				let buttonRichText = button.buttonRichText;
				target = buttonRichText.urlTarget == 'Same Tab' ? '_self' : '_blank';
				url = (
					<div className={buttonRichText.buttonClass}>
						<a
							href={buttonUrl}
							target={target}
						>
							{buttonRichText.buttonText}
						</a>
						{
							(buttonRichText && buttonRichText.textInline) && (
								<div className="text-inline">
									<p>{buttonRichText.textInline}</p>
								</div>)
						}
					</div>
				)
			}
		}
	}

	if (button.urlToUse == 'External URL') {
		url = (
			<div className='button-custom'><a
				className={'button-' + button.buttonStyle.toLowerCase() + (button.buttonClass ? ' ' + button.buttonClass : '')}
				href={button.externalUrl ? changeTrackingCode(button.externalUrl, button.trackingCode, button.trackingPhone) : '#'}
				target='_blank'
				target={target}
			>
				{button.buttonImage && <Image image={button.buttonImage} />}
				{button.buttonText}
				{
					button.hadRightIcon && (
						<SvgIcon type="right" />
					)}
			</a></div>
		)
	} else {
		if (button.internalUrl) {
			let buttonUrl = (button.internalUrl == '#') ? '#' : addSlashToStr(button.internalUrl);

			url = (
				<div className='button-custom'><a
					href={buttonUrl}
					className={'button-' + button.buttonStyle.toLowerCase() + (button.buttonClass ? ' ' + button.buttonClass : '')}
					target={target}
					onClick={button.onClick ? button.onClick : null}
				>
					{button.buttonImage && <Image image={button.buttonImage} />}
					{button.buttonText}
					{
						button.hadRightIcon && (
							<SvgIcon type="right" />
						)}
				</a></div>
			);
		}
	}

	return (
		<>{url}</>
	);
}

function mapStateToProps(state) {
	return {
		isPreview: getIsPreviewStatus(state),
		trackingCode: getTrackingCode(state),
		trackingPhone: getTrackingPhone(state),
	};
}

const ContentfulNavigationButton = connect(mapStateToProps)(NavigationButton);

export { ContentfulNavigationButton }
