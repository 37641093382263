import React from 'react'

const RowsTable = props => {
	var elm = props.arr.map((row, key) => {
		return (
			<tr key={key}>
				{row.map((value, key) => <td key={key}>{value}</td>)}
			</tr>
		)
	})
	return <>{elm}</>
}

const ContentfulBlockTable = data => {
	var dataTable = '';
	var borderStyle = ''; //without border
	if (data.type !== 'ContentfulBlockTable' && typeof data.data.table['en-US'] != 'undefined') {
		dataTable = data.data.table['en-US'].tableData;
		if (data.data.hasOwnProperty('borderStyle')) {
			borderStyle = data.data.borderStyle['en-US'];
		}
	}
	else if (data.type !== 'ContentfulBlockTable' && typeof data.data.table != 'undefined'){
		dataTable = data.data.table.tableData;
		if (data.data.hasOwnProperty('borderStyle')) {
			borderStyle = data.data.borderStyle;
		}
	}
	else {
		dataTable = data.table.tableData;
		if (typeof data.borderStyle != 'undefined' && data.borderStyle != null) {
			borderStyle = data.borderStyle.borderStyle;
		}
		
	}

	return (
		<div className={`table-responsive-x ${borderStyle}`}>
			<table width="100%" border="0" cellPadding="0" cellSpacing="0">
				<tbody>
					<RowsTable arr={dataTable}></RowsTable>
				</tbody>
			</table>
		</div>
	)
}

export { ContentfulBlockTable }
