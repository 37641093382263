import React from 'react'
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { connect } from "react-redux";
import { Image } from '../BlockLibrary/Common/Image';

const ContentfulBlockWacky = (wacky) => {
    if (wacky == null) {
        return <></>
    }
    var slugStory = '';
    slugStory = wacky.isPreview == true ? ('/wackypetnames/' + wacky.winner.fields.category.fields.slug + '/' + wacky.winner.fields.slug + '/') : ('/wackypetnames/' + wacky.winner.category.slug + '/' + wacky.winner.slug + '/');
    return (
        <>
            {
                wacky.isPreview == true ?
                    (<div className="pet-Winner">
                        {
                            wacky.winner.fields.image && (
                                <div className="avatar-pet-winner">
                                    <div className="wrap">
                                        <Image image={wacky.winner.fields.image} />
                                    </div>
                                </div>
                            )
                        }

                        <h2>{wacky.winner.fields.title}</h2>
                        <div className="btn-wacky-story">
                            <a href={slugStory}>Read Story</a>
                        </div>
                    </div>) :
                    (<div className="pet-Winner">
                        {
                            wacky.winner.image && (
                                <div className="avatar-pet-winner">
                                    <div className="wrap">
                                        <Image image={wacky.winner.image} />
                                    </div>
                                </div>
                            )
                        }

                        <h2>{wacky.winner.title}</h2>
                        <div className="btn-wacky-story">
                            <a href={slugStory}>Read Story</a>
                        </div>
                    </div>)
            }
        </>

    )
}
function mapStateToProps(state) {
    return {
        isPreview: getIsPreviewStatus(state),
    };
}
ContentfulBlockWacky.propTypes = {
    isPreview: PropTypes.bool,
    stateSelected: PropTypes.string,
    statesFile: PropTypes.array,
}

const ContentfulBlockWackyPetWinner = connect(mapStateToProps)(ContentfulBlockWacky);
export { ContentfulBlockWackyPetWinner };