import React from 'react'

export const changeTrackingCode = (url, code, phone) => {
    var omValue = code != null ? code : '';
    var trackingPhone = phone != null ? phone : '';
    var externalLink = url;

    if (url.includes('##QECURLBYENVIRONMENT##')) {
        externalLink = externalLink.replace('##QECURLBYENVIRONMENT##', process.env.GATSBY_QECURLBYENVIRONMENT);
    }
    if (url.includes('##OMCODE##') || url.includes('##CAMPAIGNPHONE##')) {
        if (omValue != '') {
            externalLink = externalLink.includes('?') ? externalLink.replace('##OMCODE##', `&OM=${omValue}`) : externalLink.replace('##OMCODE##', `?OM=${omValue}`);
        } else {
            externalLink = externalLink.replace('##OMCODE##&', '').replace('##OMCODE##', '');
        }
        if (trackingPhone != '' && typeof trackingPhone != 'undefined') {
            externalLink = externalLink.includes('&') ? externalLink.replace('##CAMPAIGNPHONE##', `ph=${trackingPhone}`) : externalLink.replace('##CAMPAIGNPHONE##', `?ph=${trackingPhone}`);
        } else {
            externalLink = externalLink.replace('##CAMPAIGNPHONE##', '');
        }
    }
    return externalLink;
}
