import React from 'react';
import { RichText } from '../richTextOptions';
import { Image } from '../BlockLibrary/Common/Image';

const ProductCardDefault = ({ card }) => {
	return (
		<div className={`product-card ${card.logoPosition == "Image Bottom" ? "image-bottom" : "image-top"}`}>
			{
				(card.logoPosition == "Image Top") &&
				(
					<div className="logo">
						<Image image={card.productLogo} />
						{card.logoContent &&
							<div className="logo-content">
								<RichText data={card.logoContent}></RichText>
							</div>
						}
					</div>
				)
			}
			{
				card.productSubtitle && (<h3 className="title">{card.productSubtitle}</h3>)
			}
			{
				card.productShortDescription && (
					<div className="description">
						<RichText data={card.productShortDescription}></RichText>
					</div>
				)
			}
			{
				card.logoPosition == "Image Bottom" &&
				(
					<div className="logo">
						<Image image={card.productLogo} />
						{card.logoContent &&
							<div className="logo-content">
								<RichText data={card.logoContent}></RichText>
							</div>
						}
					</div>
				)
			}
		</div>
	)
}

const ProductCardCardShape = ({ card }) => {
	return (
		<div className="product-card card-shape">
			<div className="product-title">
				{card.productTitle && <h3>{card.productTitle}</h3>}
			</div>
			<div className="product-content">
				<div className="logo">
					{card.productLogo && <Image image={card.productLogo} />}
					{card.logoContent &&
						<div className="logo-content">
							<RichText data={card.logoContent}></RichText>
						</div>
					}
				</div>
				{card.productShortDescription && <RichText data={card.productShortDescription}></RichText>}
			</div>
		</div>
	)
}

const ContentfulBlockProductCard = (card) => {
	var content = '';

	switch (card.productLayout) {
		case 'Card Shape':
			content = <ProductCardCardShape card={card} />
			break;
		default:
			content = <ProductCardDefault card={card} />
	}

	return (
		<>
			{content}
		</>
	)
}
export { ContentfulBlockProductCard }
