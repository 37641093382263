import React from 'react';
import { getLowerCaseUrlParam } from '../utilities';
import { SvgIcon } from './Common/SvgIcon';
import { connect } from 'react-redux';
import { getEmployerGroupsData } from '../../state/app';

class BlockSearchResults extends React.Component {
     constructor(props) {
          super(props);

          this.state = {
               currentIndex: 1,
               searchText: ''
          }
     }

     componentDidMount() {
          var searchText = getLowerCaseUrlParam(window.location.href)['q'];

          this.setState({ searchText })
     }


     goToPrePage = () => {
          this.setState({ currentIndex: this.state.currentIndex - 1 });
     }

     goToNextPage = () => {
          this.setState({ currentIndex: this.state.currentIndex + 1 });
     }

     render() {
          let employerGroups = this.props.employerGroups || [];

          if (this.state.searchText) {
               // replace(/\s+/g, ' '): Remove redundant spaces
               var searchText = decodeURIComponent(this.state.searchText).replace(/\s+/g, ' ').trim().toLowerCase();

               employerGroups = employerGroups.filter(({ node: group }) => {
                    const displayName = group.groupDisplayName.toLowerCase();
                    let checkAlternate = false;

                    if (group.alternateSearchTerm) {
                         const alternateSearchTerm = group.alternateSearchTerm.alternateSearchTerm;
                         checkAlternate = alternateSearchTerm.toLowerCase().includes(searchText)
                    }

                    return displayName.toLowerCase().includes(searchText) || checkAlternate;
               });
          }

          const employerGroupsURL = process.env.GROUP_LANDING_PAGE_URL;
          const resultsPerPage = 5;
          const numberOfPages = (Math.ceil(employerGroups.length / 5)).toString();
          const currentResults = (resultsPerPage * (this.state.currentIndex - 1));
          const resultsDisplaying = employerGroups.slice(currentResults, (currentResults + 5));
          const resultsFoundH1 = searchText ? this.props.searchResultsFoundText.replace('##SEARCHTEXT##', `"${searchText}"`) : '';
          const noResultsFoundH1 = searchText ? this.props.noResultsFoundText.replace('##SEARCHTEXT##', `"${searchText}"`) : '';

          return (
               <>
                    {
                         employerGroups.length > 0 ? (
                              <>
                                   <div className="row">
                                        <div className="col-md-9">
                                             {resultsFoundH1 && <h1>{resultsFoundH1}</h1>}
                                        </div>
                                        {
                                             numberOfPages > 1 && (
                                                  <div className="col-md-3">
                                                       <div className="pageSelection" data-numberofpages={numberOfPages}>
                                                            <ul className="pagination">
                                                                 {
                                                                      (this.state.currentIndex === 1) ? (
                                                                           <li className="prev disabled" data-lp={1}>
                                                                                <button>
                                                                                     <SvgIcon type='left' bold={true} />
                                                                                </button>
                                                                           </li>
                                                                      ) : (
                                                                                <li className="prev" data-lp={this.state.currentIndex - 1}>
                                                                                     <button onClick={this.goToPrePage}>
                                                                                          <SvgIcon type='left' bold={true} />
                                                                                     </button>
                                                                                </li>
                                                                           )
                                                                 }
                                                                 {
                                                                      (this.state.currentIndex == numberOfPages) ? (
                                                                           <li className="next disabled" data-lp={numberOfPages}>
                                                                                <button >
                                                                                     <SvgIcon type='right' bold={true} />
                                                                                </button>
                                                                           </li>
                                                                      ) : (
                                                                                <li className="next" data-lp={this.state.currentIndex + 1}>
                                                                                     <button onClick={this.goToNextPage}>
                                                                                          <SvgIcon type='right' bold={true} />
                                                                                     </button>
                                                                                </li>
                                                                           )
                                                                 }
                                                            </ul>
                                                       </div>
                                                       <div className="pageIndicator">
                                                            Page {this.state.currentIndex}
                                                       </div>
                                                  </div>
                                             )
                                        }
                                   </div>
                                   <div className="results">
                                        <ul className="searhResutls">
                                             {
                                                  resultsDisplaying.map(({ node: result }, i) => (
                                                       <li key={i}>
                                                            <a href={`${employerGroupsURL}${result.slug}`}>{result.groupDisplayName}</a>
                                                       </li>
                                                  ))
                                             }
                                        </ul>
                                   </div>
                              </>
                         ) : (
                                   <div className="row">
                                        <div className="col-md-9">
                                             {noResultsFoundH1 && <h1>{noResultsFoundH1}</h1>}
                                        </div>
                                   </div>
                              )
                    }
               </>
          )
     }
}

const mapStateToProps = (state) => {
     return {
          employerGroups: getEmployerGroupsData(state)
     }
}

const ContentfulBlockSearchResults = connect(mapStateToProps)(BlockSearchResults)

export { ContentfulBlockSearchResults }
