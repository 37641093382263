import React from 'react'
import { HtmlCode, RichText } from '../richTextOptions';
import Accordion from 'react-bootstrap/Accordion';

const ContentfulBlockFaq = (faq) => {
	const providerId = faq.sys ? faq.sys.id : faq.contentful_id;
	return (
		<>
			<Accordion.Toggle
				className="faq-question"
				aria-expanded={faq.btnActive}
				as="button"
				eventKey={providerId}
				onClick={faq.onClick}
				aria-label="toggle"
			>
				<h3 className="question"><HtmlCode data={faq.faqQuestion}></HtmlCode></h3>
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={providerId}>
				<div className="answer">
					{
						faq.faqAnswer && <RichText data={faq.faqAnswer}></RichText>
					}
				</div>
			</Accordion.Collapse>
		</>
	)
}

export { ContentfulBlockFaq }
