import React from 'react'
import { Image } from '../BlockLibrary/Common/Image'
import { RichText } from '../richTextOptions';

const ContentfulBlockMilestone = milestone => {
     return (
          <div className="milestone">
               {milestone.image && <Image image={milestone.image} />}
               <div className="milestone-content">
                    {milestone.title && <h3>{milestone.title}</h3>}
                    {milestone.shortDescription && <RichText data={milestone.shortDescription}></RichText>}
                    {milestone.year && <span className="year">{milestone.year}</span>}
               </div>
          </div>
     )
}

export { ContentfulBlockMilestone }
