import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import Autosuggest from 'react-autosuggest';
import { getIsPreviewStatus } from '../../state/app';
import PropTypes from "prop-types";
import { getEmployerGroups } from '../Hooks/EmployerGroups';
import axios from 'axios';
import Highlighter from "react-highlight-words";

import { SvgIcon } from './Common/SvgIcon';

const contentful = require('contentful')

const client = contentful.createClient({
     space: process.env.CONTENTFUL_SPACE_ID,
     accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
})

// highlightIndex: Index of matched text
const Highlight = ({ children, highlightIndex }) => (
     <span style={{ fontWeight: 'bold', 'fontFamily': 'gotham' }}>{children}</span>
);

class PreviewEmployerGroupsSearching extends Component {
     constructor(props) {
          super(props);

          this.state = {
               employerGroups: [],
               suggestions: [],
               employerGroupsURL: process.env.GROUP_LANDING_PAGE_URL,
               searchInputText: '',
               groupURLSelected: ''
          }
     }

     onSearchInputTextChanged = (text) => this.setState({ searchInputText: text })

     // When suggestion is clicked, Autosuggest needs to populate the input
     // based on the clicked suggestion. Teach Autosuggest how to calculate the
     // input value for every given suggestion.
     getSuggestionValue = ({ fields: suggestion }) => {
          // GDN: Group Display Name
          const displayName = suggestion.groupDisplayName;
          return displayName;
     }

     // Use your imagination to render suggestions.
     renderSuggestion = ({ fields: suggestion }) => {
          const displayName = suggestion.groupDisplayName;

          return (
               <a href={this.state.employerGroupsURL + suggestion.slug}>
                    <Highlighter
                         searchWords={[this.state.searchInputText]}
                         autoEscape={true}
                         textToHighlight={displayName}
                         highlightTag={Highlight}
                    />
               </a>
          );
     }

     onChange = (event, { newValue }) => {
          this.setState({ searchInputText: newValue });
     };

     // Autosuggest will call this function every time you need to update suggestions.
     // You already implemented this logic above, so just use it.
     onSuggestionsFetchRequested = ({ value }) => {
          const inputValue = value.trim().toLowerCase();

          inputValue.length >= 3 && client.getEntries({
               'content_type': 'employerGroups',
               'query': inputValue,
               'fields.searchable[in]': true,
               'fields.groupType[exists]': true, // Get entries that have value for Group Type field
               order: 'fields.groupDisplayName',
               limit: 50
          })
               .then((response) => {
                    let employerGroups = [];

                    if (this.props.groupTypeToTarget != 'All' && this.props.groupTypeToTarget != null) {
                         employerGroups = response.items.filter(({ fields: group }) => {
                              return group.groupType == this.props.groupTypeToTarget
                         });
                    } else {
                         employerGroups = response.items;
                    }

                    this.setState({ suggestions: employerGroups });
               })
               .catch(console.error)
     };

     // Autosuggest will call this function every time you need to clear suggestions.
     onSuggestionsClearRequested = () => {
          this.setState({ suggestions: [] });
     };

     onSuggestionHighlighted = ({ suggestion }) => {
          if (suggestion) {
               this.setState({ groupURLSelected: this.state.employerGroupsURL + suggestion.fields.slug })
          } else {
               this.setState({ groupURLSelected: '' })
          }
     }

     onKeyDown = (e) => {
          if (e.keyCode == '13') {
               if (typeof window != 'undefined') {
                    if (this.state.groupURLSelected) {
                         window.location.href = this.state.groupURLSelected;
                    }
                    else {
                         window.location.href = this.state.suggestions.length == 1 ?
                              `${this.state.employerGroupsURL}${this.state.suggestions[0].fields.slug}`
                              : `/employee-benefits/company-search/results/?q=${encodeURIComponent(this.state.searchInputText)}`;
                    }
               }
          }
     }

     searchIconClicked = () => {
          if (typeof window != 'undefined' && this.state.searchInputText) {
               window.location.href = `/employee-benefits/company-search/results/?q=${encodeURIComponent(this.state.searchInputText)}`;
          }
     }

     render() {
          const inputProps = {
               placeholder: this.props.inputPlaceholder,
               value: this.state.searchInputText,
               onChange: this.onChange,
               onKeyDown: this.onKeyDown,
               'aria-label': 'Your company name'
          };

          return (
               <div className="custom-search">
                    {this.props.searchTitle && (<div className="custom-search-title"><p><strong>{this.props.searchTitle}</strong></p></div>)}
                    <div className="custom-search-input">
                         <Autosuggest
                              suggestions={this.state.suggestions}
                              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              onSuggestionHighlighted={this.onSuggestionHighlighted}
                              inputProps={inputProps}
                         />
                         {
                              this.props.searchIcon && (
                                   <div className="search-icon" onClick={this.searchIconClicked}>
                                        <SvgIcon type='search' />
                                   </div>)
                         }
                    </div>
               </div>
          )
     }
}

const EmployerGroupsSearching = (props) => {
     const [searchInputText, setSearchInputText] = useState('');
     const [suggestions, setSuggestions] = useState([]);
     const [groupURLSelected, setGroupURLSelected] = useState('');
     let employerGroups = getEmployerGroups().filter(({ node: group }) => {
          return group.groupDisplayName;
     });
     const employerGroupsURL = process.env.GROUP_LANDING_PAGE_URL;

     if (props.groupTypeToTarget != 'All' && props.groupTypeToTarget != null) {
          employerGroups = employerGroups.filter(({ node: group }) => {
               return group.groupType == props.groupTypeToTarget
          });
     }

     // Teach Autosuggest how to calculate suggestions for any given input value.
     const getSuggestions = value => {
          const inputValue = value.trim().toLowerCase();
          const inputLength = inputValue.length;

          return inputLength < 3 ? [] : employerGroups.filter(({ node: group }) => {
               // const displayName = group.alternateSearchTerm ?
               //      `${group.groupDisplayName} (${group.alternateSearchTerm.alternateSearchTerm})`.toLowerCase()
               //      : group.groupDisplayName.toLowerCase();

               let checkAlternate = false;
               if (group.alternateSearchTerm) {
                    const alternateSearchTerm = group.alternateSearchTerm.alternateSearchTerm;
                    checkAlternate = alternateSearchTerm.toLowerCase().includes(inputValue)
               }

               const displayName = group.groupDisplayName.toLowerCase();

               return displayName.toLowerCase().includes(inputValue) || checkAlternate;
          });
     };

     // When suggestion is clicked, Autosuggest needs to populate the input
     // based on the clicked suggestion. Teach Autosuggest how to calculate the
     // input value for every given suggestion.
     const getSuggestionValue = ({ node: suggestion }) => {
          // GDN: Group Display Name
          // let isMatchedGDN = false;

          // if (suggestion.alternateSearchTerm) {
          //      isMatchedGDN = suggestion.groupDisplayName.toLowerCase().includes(searchInputText.toLowerCase());
          // } else {
          //      isMatchedGDN = true;
          // }

          // const displayName = isMatchedGDN ? suggestion.groupDisplayName : `${suggestion.groupDisplayName} (${suggestion.alternateSearchTerm.alternateSearchTerm})`;

          const displayName = suggestion.groupDisplayName;

          return displayName;
     }


     // Use your imagination to render suggestions.
     const renderSuggestion = ({ node: suggestion }) => {
          // GDN: Group Display Name
          // let isMatchedGDN = false;

          // if (suggestion.alternateSearchTerm) {
          //      isMatchedGDN = suggestion.groupDisplayName.toLowerCase().includes(searchInputText.toLowerCase());
          // } else {
          //      isMatchedGDN = true;
          // }

          // const displayName = isMatchedGDN ? suggestion.groupDisplayName : `${suggestion.groupDisplayName} (${suggestion.alternateSearchTerm.alternateSearchTerm})`;

          const displayName = suggestion.groupDisplayName;
          return (
               <a href={employerGroupsURL + suggestion.slug}>
                    <Highlighter
                         searchWords={[searchInputText]}
                         autoEscape={true}
                         textToHighlight={displayName}
                         highlightTag={Highlight}
                    />
               </a>
          );
     }

     const onChange = (event, { newValue }) => {
          setSearchInputText(newValue)
     };

     // Autosuggest will call this function every time you need to update suggestions.
     // You already implemented this logic above, so just use it.
     const onSuggestionsFetchRequested = ({ value }) => {
          setSuggestions(getSuggestions(value))
     };

     // Autosuggest will call this function every time you need to clear suggestions.
     const onSuggestionsClearRequested = () => {
          setSuggestions([])
     };

     const onSuggestionHighlighted = ({ suggestion }) => {
          if (suggestion) {
               setGroupURLSelected(employerGroupsURL + suggestion.node.slug);
          } else {
               setGroupURLSelected('');
          }
     }

     const onKeyDown = (e) => {
          if (e.keyCode == '13') {
               if (typeof window != 'undefined') {
                    if (groupURLSelected) {
                         window.location.href = groupURLSelected;
                    }
                    else {
                         window.location.href = (suggestions.length == 1) ?
                              `${employerGroupsURL}${suggestions[0].node.slug}`
                              : `/employee-benefits/company-search/results/?q=${encodeURIComponent(searchInputText)}`;
                    }
               }
          }
     }

     const inputProps = {
          placeholder: props.inputPlaceholder,
          value: searchInputText,
          onChange: onChange,
          onKeyDown: onKeyDown,
          'aria-label': 'Your company name'
     };

     const searchIconClicked = () => {
          if (typeof window != 'undefined' && searchInputText) {
               window.location.href = `/employee-benefits/company-search/results/?q=${encodeURIComponent(searchInputText)}`;
          }
     }
     
     return (
          <div className="custom-search">
               {props.searchTitle && (<div className="custom-search-title"><p><strong>{props.searchTitle}</strong></p></div>)}
               <div className="custom-search-input">
                    <Autosuggest
                         suggestions={suggestions}
                         onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                         onSuggestionsClearRequested={onSuggestionsClearRequested}
                         getSuggestionValue={getSuggestionValue}
                         renderSuggestion={renderSuggestion}
                         onSuggestionHighlighted={onSuggestionHighlighted}
                         inputProps={inputProps}
                    />
                    {
                         props.searchIcon && (
                              <div className={`search-icon ${(searchInputText) ? 'cursor-pointer' : ''}`} onClick={searchIconClicked}>
                                   <SvgIcon type='search' />
                              </div>)
                    }
               </div>
          </div>
     );
}

const SearchWithEmployerGroups = (props) => {
     return (
          <>
               {props.isPreview ? <PreviewEmployerGroupsSearching {...props} /> : <EmployerGroupsSearching {...props} />}
          </>
     );
}

class SearchWithFileData extends Component {
     constructor(props) {
          super(props);

          this.state = {
               error: null,
               isLoaded: false,
               items: [],
               suggestions: [],
               searchInputText: '',
               groupURLSelected: '',
               buttonClicked: false,
          }
     }

     showResultBox = () => {
          this.setState({ buttonClicked: true })
     }

     hideResultBox = () => {
          this.setState({ buttonClicked: false })
     }

     componentDidMount() {
          if (typeof this.props.data != 'undefined' && this.props.data != null) {
               let dataUrl = this.props.isPreview ? this.props.data.fields.file.url : this.props.data.file.url;
               axios.get(dataUrl)
                    .then(res => {
                         this.setState({ isLoaded: true, items: res.data });
                    })
                    .catch(error => {
                         this.setState({ isLoaded: true, error });
                    })
          }

          window.addEventListener('click', (e) => {
               if (!document.getElementById('custom-search').contains(e.target)) {
                    // Clicked outside
                    this.hideResultBox();
               }
          });
     }

     // Teach Autosuggest how to calculate suggestions for any given input value.
     getSuggestions = value => {
          const inputValue = value.trim().toLowerCase();
          const inputLength = inputValue.length;

          return inputLength == 0 ? [] : this.state.items.filter(item => (
               item.text.toLowerCase().includes(inputValue)
          ))
     };

     // When suggestion is clicked, Autosuggest needs to populate the input
     // based on the clicked suggestion. Teach Autosuggest how to calculate the
     // input value for every given suggestion.
     getSuggestionValue = (suggestion) => suggestion.text

     // Use your imagination to render suggestions.
     renderSuggestion = (suggestion) => (
          <a href={suggestion.site} target='_blank'>
               {suggestion.text}
          </a>
     )

     onChange = (event, { newValue }) => {
          this.setState({ searchInputText: newValue });
     };

     // Autosuggest will call this function every time you need to update suggestions.
     // You already implemented this logic above, so just use it.
     onSuggestionsFetchRequested = ({ value }) => {
          this.setState({ suggestions: this.getSuggestions(value) });
     };

     // Autosuggest will call this function every time you need to clear suggestions.
     onSuggestionsClearRequested = () => {
          this.setState({ suggestions: [] });
     };

     onSuggestionHighlighted = ({ suggestion }) => {
          if (suggestion) {
               this.setState({ groupURLSelected: suggestion.site })
          } else {
               this.setState({ groupURLSelected: '' })
          }
     }

     onKeyDown = (e) => {
          if (e.keyCode == '13') {
               if (typeof window != 'undefined' && this.state.groupURLSelected) {
                    window.location.href = this.state.groupURLSelected;
               }
          }
     }

     searchInputToggle = () => this.setState({ buttonClicked: !this.state.buttonClicked })

     render() {
          const inputProps = {
               placeholder: this.props.inputPlaceholder,
               value: this.state.searchInputText,
               onChange: this.onChange,
               onKeyDown: this.onKeyDown,
               'aria-label': 'Your company name'
          };

          return (
               this.props.searchButton ? (
                    <>
                         <div id="custom-search" className="custom-search-input" >
                              <button className="dropbtn" type="button" onClick={this.searchInputToggle}>
                                   {this.props.searchTitle && this.props.searchTitle}
                              </button>
                              <div className={`dropdown-content${this.state.buttonClicked ? " open-search" : ""}`}>
                                   <input
                                        type="text"
                                        className="search-query"
                                        aria-label="Search Query"
                                        value={this.state.searchInputText}
                                        onChange={e => this.setState({ searchInputText: e.target.value })}
                                        placeholder="Search.."
                                        onFocus={() => this.showResultBox()}
                                   />
                                   {this.state.items.length > 0 && this.state.items.map((item, i) => {
                                        if (item.text.toLowerCase().includes(this.state.searchInputText.toLowerCase())) {
                                             return (
                                                  <p key={i}><a href={item.site} target="_blank" aria-label={item.text + " Search"}>{item.text}</a></p>
                                             )
                                        }
                                   })}
                              </div>
                         </div>
                    </>
               ) : (
                         <div className="custom-search">
                              {this.props.searchTitle && (<div className="custom-search-title"><p><strong>{this.props.searchTitle}</strong></p></ div>)}
                              <div className="custom-search-input">
                                   <Autosuggest
                                        suggestions={this.state.suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        onSuggestionHighlighted={this.onSuggestionHighlighted}
                                        inputProps={inputProps}
                                   />
                                   {this.props.searchIcon && <div className="search-icon"><SvgIcon type='search' /></div>}
                              </div>
                         </div>
                    )
          );
     }
}

const BlockSearch = (props) => {
     return (
          <>
               {
                    (typeof props.data != 'undefined' && props.data != null) ?
                         <SearchWithFileData {...props} /> :
                         <SearchWithEmployerGroups {...props} />
               }
          </>
     )
};


BlockSearch.propTypes = {
     isPreview: PropTypes.bool,
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

const ContentfulBlockSearch = connect(mapStateToProps)(BlockSearch)

export { ContentfulBlockSearch }
