import React from 'react';
import { Image } from '../BlockLibrary/Common/Image';
import Block from '../block';

const ContentfulBlockInsuranceOptions = (option) => {

     return (
          <div className="insurance-option">
               <div className="option-image">
                    <Image image={option.image} />
               </div>
               <div className="option-link">
                    <Block block={option.callToAction} hadRightIcon={true}></Block>
                    <h4>{option.shortDescription ? option.shortDescription : option.shortDes}</h4>
               </div>
          </div>
     )
}

export { ContentfulBlockInsuranceOptions }
