import React from 'react'
import Block from '../block';
import { Image } from './Common/Image';

const ContentfulSectionFeaturedCategory = (category) => {
     return (
          <section className={`home-categories`}>
               <div className="container">
                    <div className="wrapper">
                         <div className="cf-content">
                              {category.categoryName && <h3>{category.categoryName}</h3>}
                              {category.categoryShortDescription && <p>{category.categoryShortDescription}</p>}
                              {category.ctaButton && <Block block={category.ctaButton} />}
                         </div>
                         <div className="cf-image">
                              {category.image && <Image image={category.image} />}
                         </div>
                    </div>
               </div>
          </section>
     )
}

export { ContentfulSectionFeaturedCategory }
