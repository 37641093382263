import React from 'react';
import Block from '../block';
import { Image } from '../BlockLibrary/Common/Image';

const ContentfulSectionCoverageDetailsWithImage = ({ data }) => {
	return (
		<section className={`coverage-details`}>
			<div className="container">
				<div className="row">
					<div className="col-lg-4">
						<div className="bird-image">
							<Image image={data.image} />
						</div>
					</div>
					<div className="col-lg-8 bird-list">
						{data.rows && data.rows.map((row, i) => (<Block key={i} block={row} />))}
					</div>
				</div>
			</div>
		</section>
	)
}

const ContentfulSectionCoverageDetailsWithoutImage = ({ data }) => {
	return (
		<section className={`coverage-details not-bird`}>
			<div className="container">
				{data.rows && data.rows.map((row, i) => (<Block key={i} block={row} />))}
			</div>
		</section>
	)
}

const ContentfulSectionCoverageDetails = (coverageDetails) => {
	return (
		<>
			{
				coverageDetails.layout == 'WithImage' ?
					<ContentfulSectionCoverageDetailsWithImage data={coverageDetails} /> :
					<ContentfulSectionCoverageDetailsWithoutImage data={coverageDetails} />
			}
		</>

	)
}

export { ContentfulSectionCoverageDetails }
