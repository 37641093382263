import React from 'react'
import Block from '../block'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { RichText, HtmlCode } from '../richTextOptions'
import { Image } from '../BlockLibrary/Common/Image'
import { getIsPreviewStatus } from '../../state/app'

const SectionFeaturedList = featuredList => {

  if (featuredList.replaceImageWithVideo == 'Yes') {
    var video = featuredList.isPreview
      ? featuredList.video
      : featuredList.video.video
  }

  return (
    <section
      className={`featured-list`}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="featured-list-title">
              {featuredList.titleFeaturedList && (
                <h3>{featuredList.titleFeaturedList}</h3>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {featuredList.imagePosition == 'Image Left' && (
            <div className="col-lg-5 col-md-6 image-left">
              {featuredList.replaceImageWithVideo == 'No'
                ? featuredList.image && <Image image={featuredList.image} />
                : video && <HtmlCode data={video} />} 
            </div>
          )}
          <div className="col-lg-7 col-md-6">
            <div
              className={
                `content` + (featuredList.video ? ' content-video' : '')
              }
            >
              {featuredList.listContent && (
                <RichText data={featuredList.listContent}></RichText>
              )}
              {featuredList.ctaButton && (
                <Block block={featuredList.ctaButton} />
              )}
            </div>
          </div>
          {featuredList.imagePosition == 'Image Right' && (
            <div className="col-lg-5 col-md-6 image-right">
              {featuredList.replaceImageWithVideo == 'No'
                ? featuredList.image && <Image image={featuredList.image} />
                : video && <HtmlCode data={video} />}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => {
  return {
    isPreview: getIsPreviewStatus(state),
  }
}

SectionFeaturedList.propTypes = {
  isPreview: PropTypes.bool,
}

const ContentfulSectionFeaturedList = connect(mapStateToProps)(
  SectionFeaturedList
)

export { ContentfulSectionFeaturedList }
