import React from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';

const BlockSelectValue = ({ selectValue, isPreview }) => {
    return (
        <>
            {isPreview ? (
                selectValue.map((node, i) => (
                    <option key={i} value={node.fields.value}>{node.fields.name}</option>
                ))
            ) : (
                    selectValue.map((node, i) => (
                        <option key={i} value={node.value}>{node.name}</option>
                    ))
                )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isPreview: getIsPreviewStatus(state)
    };
}

BlockSelectValue.propTypes = {
    isPreview: PropTypes.bool,
}

const ContentfulBlockSelectValue = connect(mapStateToProps)(BlockSelectValue);

export { ContentfulBlockSelectValue }