import React from 'react';
import Block from '../block';
import { RichText, HtmlCode } from '../richTextOptions';
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { connect } from "react-redux";

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state),
     };
}
const BlockForm = (form) => {
     return (
          <div className="block-form">
               {
                    form.formCategoryName && (
                         // <h4 >{form.isPreview ? form.formCategoryName : form.formCategoryName.formCategoryName}</h4>
                         <>
                              {(form.isPreview) ? (
                                   <h4>{form.formCategoryName}</h4>
                                   ) : (
                                        <h4 dangerouslySetInnerHTML={{
                                             __html: form.formCategoryName.childMarkdownRemark.html
                                        }}></h4>
                                   )
                              }
                         </>
                         
                    )
               }

               {
                    form.shortDescription && <RichText data={form.shortDescription}></RichText>
               }

               {
                    form.formFiles && (
                         <ul>
                              {
                                   form.formFiles.map((file, i) => {
                                        return (<li key={i}><Block block={file} ></Block></li>)
                                   })
                              }
                         </ul>
                    )
               }
          </div>
     )
}

BlockForm.propTypes = {
     isPreview: PropTypes.bool,
}

const ContentfulBlockForm = connect(mapStateToProps)(BlockForm);

export { ContentfulBlockForm }
