import React, { Component } from 'react';
import { RichText, HtmlCode } from '../richTextOptions';
import Block from '../block';

class ContentfulSectionVideoEmbed extends Component {
     componentDidMount() {
          const script1 = document.createElement("script");
          const script2 = document.createElement("script");

          script1.src = "https://fast.wistia.com/embed/medias/videolink.jsonp";
          script1.async = true;

          script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
          script2.async = true;

          document.body.appendChild(script1);
          document.body.appendChild(script2);
     }

     render() {
          const { title, ctaButton, script } = this.props;

          return (
               <>
                    <section className={`video-page-content`}>
                         <div className="container">
                              <div className="row video-intro-hero">
                                   <div className="col-lg-8">
                                        {
                                             title && <RichText data={title}></RichText>
                                        }
                                   </div>
                                   {
                                        ctaButton && (
                                             <div className="col-lg-4">
                                                  <Block block={ctaButton} />
                                             </div>
                                        )
                                   }
                              </div>
                              <HtmlCode data={script} className={"video-container"}></HtmlCode>
                         </div>
                    </section>
               </>
          );
     }
};

export { ContentfulSectionVideoEmbed }
