import React from 'react';
import Modal from 'react-bootstrap/Modal';
import window from 'global';
import { Image } from '../BlockLibrary/Common/Image';
import { HtmlCode } from '../richTextOptions';

class ContentfulSectionECardForm extends React.Component {
     constructor(props) {
          super(props);

          this.state = {
               showPreview: false,
               showFacebookShare: false,
               referrerEmail: '',
               stateSelect: '',
               isNextBtnClicked: false
          }
     }

     handleShowPreview = () => this.setState({ showPreview: true });
     handleClosePreview = () => this.setState({ showPreview: false });

     handleShowFacebookShare = (e) => {
          e.preventDefault();
          this.setState({ showFacebookShare: true });
     }

     handleCloseFacebookShare = () => {
          this.setState({ showFacebookShare: false, isNextBtnClicked: false });
     }

     onReferrerEmailChanged = (e) => this.setState({ referrerEmail: e.target.value });
     onStateSelectChanged = (e) => this.setState({ stateSelect: e.target.value });

     fbShare = () => {
          this.setState({ isNextBtnClicked: true });

          if (this.state.referrerEmail && this.state.stateSelect) {
               window.open('https://www.facebook.com/dialog/share?app_id=161226177842045&display=popup&href=https://pages.email.petinsurance.com/page.aspx?QS=773ed3059447707dcade81f80d19c64c51339c74a490f74f20d98f4d132f6d19%26state%3D' + this.state.referrerEmail + '%26email%3D' + this.state.referrerEmail, 'facebook-share-dialog', 'height=450, width=550');
          }
     }

     render() {
          const { showPreview, showFacebookShare, referrerEmail, stateSelect, isNextBtnClicked } = this.state;

          return (
               <section className={`e-card-form-content`}>
                    <div className="container">
                         <div className="row">
                              {this.props.template == 'Share on Facebook' ? (
                                   <>
                                        <div className="col-lg-8">
                                             <h5>Tell us where to send the e-card.</h5>
                                             <p className="required">*required field</p>
                                             <HtmlCode data={this.props.formScript}></HtmlCode>
                                             <p className="privacyPolicy">
                                                  We promise never to sell you or your friends information. See our <a href="https://www.nationwide.com/privacy-security.jsp" target="_blank">
                                                       privacy policy
                                                  </a>
                                             </p>
                                        </div>
                                        <div className="col-lg-4">
                                             <div className="e-card-image">
                                                  {this.props.eCardImage && <Image image={this.props.eCardImage} />}
                                                  <div className="fb-share text-center">
                                                       <a href="#" onClick={this.handleShowFacebookShare} aria-label="Share">
                                                            <img src={'/images/facebook-share.jpg'} alt="facebook" />
                                                       </a>
                                                  </div>
                                                  <div className="text-center">
                                                       <button className="preview-btn" onClick={this.handleShowPreview}>Preview</button>
                                                  </div>
                                             </div>
                                        </div>
                                   </>
                              ) : (
                                        <>
                                             <div className="col-lg-6">
                                                  <h5>Tell us where to send the e-card.</h5>
                                                  <p className="required">*required field</p>
                                                  <HtmlCode data={this.props.formScript}></HtmlCode>
                                             </div>
                                             <div className="col-lg-2"></div>
                                             <div className="col-lg-4">
                                                  <div className="e-card-image">
                                                       {this.props.eCardImage && <Image image={this.props.eCardImage} />}
                                                       <div className="text-center">
                                                            <button className="preview-btn" onClick={this.handleShowPreview}>Preview</button>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="privacy-policy-text">
                                                  <p className="privacyPolicy">
                                                       We promise never to sell you or your friends information. See our <a href="https://www.nationwide.com/privacy-security.jsp" target="_blank">
                                                            privacy policy
                                             </a>
                                                  </p>
                                             </div>
                                        </>
                                   )}
                         </div>
                    </div>

                    {/* Share on Facebook modal */}
                    <Modal className="facebook-share" show={showFacebookShare} onHide={this.handleCloseFacebookShare}>
                         <Modal.Body>
                              <button className="close" onClick={this.handleCloseFacebookShare} aria-label="Close">
                                   <span>×</span>
                              </button>
                              <div className="col-lg-12">
                                   <h4 style={{ fontSize: "22px" }} className="nw-font-color">
                                        For every Facebook friend who clicks on your post, you&rsquo;ll get an <span>entry into the Pet Crew Sweepstakes</span>!
                                   </h4>
                                   <p className="nw-font-color required-field">*required field</p>
                              </div>
                              <div className="col-lg-12">
                                   <div id="formInputs">
                                        <div className={`form-group${(isNextBtnClicked && !referrerEmail) ? ' error' : ''}`}>
                                             <label htmlFor="referrerEmail">Your Email*</label>
                                             <input
                                                  id="referrerEmail"
                                                  type="text"
                                                  name="referrerEmail"
                                                  className="form-control"
                                                  maxLength="60"
                                                  onChange={this.onReferrerEmailChanged}
                                                  value={referrerEmail}
                                                  aria-describedby="referrer-email-validation"
                                             />
                                             <p id="referrerEmailError" className="error-message" id="referrer-email-validation">Please enter your email.</p>
                                        </div>
                                        <div className={`form-group${(isNextBtnClicked && !stateSelect) ? ' error' : ''}`}>
                                             <label htmlFor="stateSelect">Your State*</label>
                                             <select aria-label="Your select state" aria-describedby="referrerEmailError" className="form-control" id="state" name="stateSelect" onChange={this.onStateSelectChanged}>
                                                  <option value="">Select State</option>
                                                  <option value="AL">Alabama</option>
                                                  <option value="AK">Alaska</option>
                                                  <option value="AZ">Arizona</option>
                                                  <option value="AR">Arkansas</option>
                                                  <option value="CA">California</option>
                                                  <option value="CO">Colorado</option>
                                                  <option value="CT">Connecticut</option>
                                                  <option value="DE">Delaware</option>
                                                  <option value="DC">District Of Columbia</option>
                                                  <option value="FL">Florida</option>
                                                  <option value="GA">Georgia</option>
                                                  <option value="HI">Hawaii</option>
                                                  <option value="ID">Idaho</option>
                                                  <option value="IL">Illinois</option>
                                                  <option value="IN">Indiana</option>
                                                  <option value="IA">Iowa</option>
                                                  <option value="KS">Kansas</option>
                                                  <option value="KY">Kentucky</option>
                                                  <option value="LA">Louisiana</option>
                                                  <option value="ME">Maine</option>
                                                  <option value="MD">Maryland</option>
                                                  <option value="MA">Massachusetts</option>
                                                  <option value="MI">Michigan</option>
                                                  <option value="MN">Minnesota</option>
                                                  <option value="MS">Mississippi</option>
                                                  <option value="MO">Missouri</option>
                                                  <option value="MT">Montana</option>
                                                  <option value="NE">Nebraska</option>
                                                  <option value="NV">Nevada</option>
                                                  <option value="NH">New Hampshire</option>
                                                  <option value="NJ">New Jersey</option>
                                                  <option value="NM">New Mexico</option>
                                                  <option value="NY">New York</option>
                                                  <option value="NC">North Carolina</option>
                                                  <option value="ND">North Dakota</option>
                                                  <option value="OH">Ohio</option>
                                                  <option value="OK">Oklahoma</option>
                                                  <option value="OR">Oregon</option>
                                                  <option value="PA">Pennsylvania</option>
                                                  <option value="RI">Rhode Island</option>
                                                  <option value="SC">South Carolina</option>
                                                  <option value="SD">South Dakota</option>
                                                  <option value="TN">Tennessee</option>
                                                  <option value="TX">Texas</option>
                                                  <option value="UT">Utah</option>
                                                  <option value="VT">Vermont</option>
                                                  <option value="VA">Virginia</option>
                                                  <option value="WA">Washington</option>
                                                  <option value="WV">West Virginia</option>
                                                  <option value="WI">Wisconsin</option>
                                                  <option value="WY">Wyoming</option>
                                             </select>
                                             <p id="referrerEmailError" className="error-message">Please select a state.</p>
                                             <p className="rules"><em>Void where prohibited. Sweepstakes open only to East coast residents.</em></p>
                                        </div>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col-lg-12 disclaimer">
                                        <p className="text-center disclaimer-gray" style={{ fontSize: "12px", paddingTop: "20px" }}>
                                             No purchase necessary. By sharing an e-card, you are agreeing to the
                                             <a href="http://image.email.petinsurance.com/lib/fe9b1570716d007f75/m/4/9df0ed7e-68a8-466e-a80c-a74bc55cf3c8.pdf" target="_blank" aria-label="Link to Rules"> rules</a>
                                             . We promise to never sell your or your friend&rsquo;s information. See our
                                             <a href="https://www.nationwide.com/privacy-security.jsp" target="_blank" target="_blank"> Privacy Policy</a>.
                                        </p>
                                   </div>
                                   <div className="col-lg-12">
                                        <div style={{ paddingTop: "10px" }} className="text-center">
                                             <button aria-label="Next Share" className="btn btn-info share-btn" type="button" onClick={this.fbShare}>Next</button>
                                        </div>
                                   </div>
                              </div>
                         </Modal.Body>
                    </Modal>

                    {/* e-card preview modal */}
                    <Modal show={showPreview} onHide={this.handleClosePreview}>
                         <Modal.Body>
                              <button className="close" onClick={this.handleClosePreview} aria-label="Close">
                                   <span>×</span>
                              </button>
                              {this.props.modalImage && <Image image={this.props.modalImage} />}
                         </Modal.Body>
                    </Modal>
               </section >
          )
     }
}

export { ContentfulSectionECardForm }
