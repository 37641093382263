import React from 'react';
import Block from '../block';
import { RichText } from '../richTextOptions';
import { Image } from '../BlockLibrary/Common/Image';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';

const ContentfulSectionIntroHeroLayoutWithoutImage = ({ data }) => {
     let h1 = null;
     let h2 = null;
     if (typeof data.__typename != 'undefined' && data.h1 != null) {
          h1 = data.h1.h1;
     } else {
          h1 = data.h1;
     }
     if (typeof data.__typename != 'undefined' && data.h2 != null) {
          h2 = data.h2.h2;
     } else {
          h2 = data.h2;
     }

     return (
          <div className="no-image content-wrap">
               {data.h1 && <h1>{h1}</h1>}
               {data.h2 && <h2>{h2}</h2>}
               {data.bodyText && <RichText data={data.bodyText}></RichText>}
               {data.ctaButton && <Block block={data.ctaButton} />}
          </div>
     )
}

const ContentfulSectionIntroHeroLayoutWithImage = ({ data }) => {
     let h1 = null;
     if (typeof data.__typename != 'undefined' && data.h1 != null) {
          h1 = data.h1.h1;
     } else {
          h1 = data.h1;
     }
     let h2 = null;
     if (typeof data.__typename != 'undefined' && data.h2 != null) {
          h2 = data.h2.h2;
     } else {
          h2 = data.h2;
     }
     return (
          <>
               <div className="row">
                    {
                         data.layout == 'Image Left' && (
                              <div className="order-2 order-lg-1 col-lg-4 img-center">
                                   {data.image && (
                                        <Image image={data.image} />
                                   )}
                              </div>
                         )
                    }
                    <div className="order-1 order-lg-2 col-lg-8">
                         <div className="has-image content-wrap">
                              {
                                   data.imageAboveH1 &&
                                   <Image image={data.imageAboveH1} />
                              }
                              {
                                   data.h1 && <h1>{h1}</h1>
                              }
                              {
                                   data.h2 && <h2>{h2}</h2>
                              }
                              {
                                   data.bodyText && <RichText data={data.bodyText}></RichText>
                              }
                              {
                                   data.ctaButton && <Block block={data.ctaButton} />
                              }
                         </div>
                    </div>
                    {data.layout == 'Image Right' && (
                         <div className="order-2 order-lg-2 col-lg-4 img-center">
                              {
                                   data.image && (
                                        <Image image={data.image} />
                                   )
                              }
                         </div>
                    )}
               </div>
          </>
     )
}

const SectionIntroHero = blockData => {
     var sectionClass = '';
     switch (blockData.separator) {
          case 'No Separator, standard padding':
               sectionClass = 'standard-padding';
               break;
          case 'Thin line, no padding':
               sectionClass = 'thin-line no-padding';
               break;
          case 'Thin line, standard padding':
               sectionClass = 'thin-line standard-padding';
               break;
          case 'No line, no padding bottom':
               sectionClass = 'no-padding-bottom';
               break;
          case 'No line with an image':
               sectionClass = 'image-poa';
               break;
          default:
               sectionClass = '';
     }

     let backgroundImageUrl = null;

     if (blockData.isPreview) {
          backgroundImageUrl = blockData.backgroundImage ? 'url(' + blockData.backgroundImage.fields.file.url + ')' : null;
     } else {
          backgroundImageUrl = blockData.backgroundImage ? 'url(' + blockData.backgroundImage.file.url + ')' : null;
     }

     return (
          <>
               <section className={`intro-hero ${sectionClass}`} style={{ backgroundImage: backgroundImageUrl }}>
                    <div className="container">
                         {blockData.layout == 'No Image' ? (
                              <ContentfulSectionIntroHeroLayoutWithoutImage data={blockData}></ContentfulSectionIntroHeroLayoutWithoutImage>
                         ) : (
                                   <ContentfulSectionIntroHeroLayoutWithImage data={blockData}></ContentfulSectionIntroHeroLayoutWithImage>
                              )
                         }
                    </div>
               </section>
          </>
     )
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

SectionIntroHero.propTypes = {
     isPreview: PropTypes.bool,
}
const ContentfulSectionIntroHero = connect(mapStateToProps)(SectionIntroHero);

export { ContentfulSectionIntroHero }
