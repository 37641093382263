import React from 'react';
import { Image } from '../BlockLibrary/Common/Image';

const ContentfulBlockTestimonial = (testimonial) => {
     return (
          <div className="item">
               <Image image={testimonial.image} />
               <div className="testimonial">
                    <div className="wrap">
                         <div className="quote"><p>"{testimonial.quote}"</p></div>
                         <div className="speaker">
                              <p className="name">{testimonial.contentName},</p>
                              <p className="more-info">{testimonial.company}</p>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export { ContentfulBlockTestimonial }
