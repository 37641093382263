import React, { Component } from 'react';
import Block from './block';

class blocks extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		blocks = this.props.blocks;
		return (
			<>
				{
					blocks.map((block, i) => {
						return (<Block block={block} key={i}></Block>)
					})
				}
			</>
		)
	}
}

export default blocks;
