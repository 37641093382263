import React, { Component } from 'react';
import Block from '../block';
import { RichText } from '../richTextOptions';
import Accordion from 'react-bootstrap/Accordion'

const BlockContainer = ({ block }) => {
     return (
          <>
               {block.blocks &&
                    block.blocks.map((block, i) => {
                         return <Block block={block} key={i}></Block>
                    })}
          </>
     )
}

class BlockContainerFaq extends Component {
     constructor(props) {
          super(props);

          this.state = {
               activeFaqIndex: '',
          }
     }

     setActiveFaqIndex = (index) => this.setState({ activeFaqIndex: index })

     toggleDiv = (id, status) => {
		$('.collapse').map(function () {
			if (id == $(this).attr('id')) {
				(status) ? $(this).slideUp() : $(this).slideDown()
			} else {
				$(this).slideUp()
			}
		})
	}

     render() {
          const { block } = this.props;
          

          return (
               <Accordion>
                    {block.blocks &&
                         block.blocks.map((block, i) => {
                              let newBlock = block;
                              newBlock.btnActive = false
                              if (this.state.activeFaqIndex === i) {
                                   newBlock.btnActive = true
                                   return (
                                        <div className="faq active" key={i}>
                                             <Block block={newBlock} onClick={() => this.setActiveFaqIndex('')}></Block>
                                        </div>
                                   )
                              }

                              return (
                                   <div className="faq" key={i}>
                                        <Block block={newBlock} onClick={() => this.setActiveFaqIndex(i)}></Block>
                                   </div>
                              )
                         })}
               </Accordion>
               
          )
     }
}

const BlockContainer2Column = ({ block }) => {

     var col0 = typeof block.blocks[0] != 'undefined' ? block.blocks[0] : null
     var col1 = typeof block.blocks[1] != 'undefined' ? block.blocks[1] : null
     return (
          <div className="row">
               {col0 != null && (
                    <div className="col-md-6 content-left">
                         <Block block={col0}></Block>

                    </div>
               )}
               {col1 != null && (
                    <div className="col-md-6 content-right">
                         <Block block={col1}></Block>
                    </div>
               )}
          </div>
     )
}

const BlockContainer2Column57 = ({ block }) => {

     var col0 = typeof block.blocks[0] != 'undefined' ? block.blocks[0] : null
     var col1 = typeof block.blocks[1] != 'undefined' ? block.blocks[1] : null
     return (
          <div className="row">
               {col0 != null && (
                    <div className="col-md-5">
                         <Block block={col0}></Block>
                    </div>
               )}
               {col1 != null && (
                    <div className="col-md-7">
                         <Block block={col1}></Block>
                    </div>
               )}
          </div>
     )
}

const BlockContainer2Column75 = ({ block }) => {

     var col0 = typeof block.blocks[0] != 'undefined' ? block.blocks[0] : null
     var col1 = typeof block.blocks[1] != 'undefined' ? block.blocks[1] : null
     return (
          <div className="row">
               {col0 != null && (
                    <div className="col-lg-7">
                         <Block block={col0}></Block>
                    </div>
               )}
               {col1 != null && (
                    <div className="col-lg-5">
                         <Block block={col1}></Block>
                    </div>
               )}
          </div>
     )
}

const BlockContainer2Column84 = ({ block }) => {

     var col0 = typeof block.blocks[0] != 'undefined' ? block.blocks[0] : null
     var col1 = typeof block.blocks[1] != 'undefined' ? block.blocks[1] : null
     return (
          <div className="row">
               {col0 != null && (
                    <div className="col-lg-8">
                         <Block block={col0}></Block>
                    </div>
               )}
               {col1 != null && (
                    <div className="col-lg-4">
                         <Block block={col1}></Block>
                    </div>
               )}
          </div>
     )
}

const BlockContainer2Column48 = ({ block }) => {

     var col0 = typeof block.blocks[0] != 'undefined' ? block.blocks[0] : null
     var col1 = typeof block.blocks[1] != 'undefined' ? block.blocks[1] : null
     return (
          <div className="row">
               {col0 != null && (
                    <div className="col-lg-4">
                         <Block block={col0}></Block>
                    </div>
               )}
               {col1 != null && (
                    <div className="col-lg-8">
                         <div className="content-right">
                              <Block block={col1}></Block>
                         </div>
                    </div>
               )}
          </div>
     )
}

const BlockContainer2Column48Md = ({ block }) => {

     var col0 = typeof block.blocks[0] != 'undefined' ? block.blocks[0] : null
     var col1 = typeof block.blocks[1] != 'undefined' ? block.blocks[1] : null
     return (
          <div className="row">
               {col0 != null && (
                    <div className="col-md-4">
                         <Block block={col0}></Block>
                    </div>
               )}
               {col1 != null && (
                    <div className="col-md-8">
                         <div className="content-right">
                              <Block block={col1}></Block>
                         </div>
                    </div>
               )}
          </div>
     )
}

const BlockContainer3Column = ({ block }) => {

     var col0 = typeof block.blocks[0] != 'undefined' ? block.blocks[0] : null
     var col1 = typeof block.blocks[1] != 'undefined' ? block.blocks[1] : null
     var col2 = typeof block.blocks[2] != 'undefined' ? block.blocks[2] : null
     return (
          <div className="row">
               {col0 != null && (
                    <div className="col-4">
                         <Block block={col0}></Block>
                    </div>
               )}
               {col1 != null && (
                    <div className="col-4">
                         <Block block={col1}></Block>
                    </div>
               )}
               {col2 != null && (
                    <div className="col-4">
                         <Block block={col2}></Block>
                    </div>
               )}
          </div>
     )
}

const ContentfulBlockContainer = block => {
     var content = '';

     if (block.blocks != null) {
          switch (block.containerStyle) {
               case 'NoFull-Nocolumn-Faq':
                    content = (
                         <BlockContainerFaq block={block}></BlockContainerFaq>
                    )
                    break;
               case '2-Column':
                    content = (
                         <BlockContainer2Column block={block}></BlockContainer2Column>
                    )
                    break;
               case '2-Column-5/7':
                    content = (
                         <BlockContainer2Column57 block={block}></BlockContainer2Column57>
                    )
                    break;
               case '2-Column-7/5':
                    content = (
                         <BlockContainer2Column75 block={block}></BlockContainer2Column75>
                    )
                    break;
               case '2-Column-8/4':
                    content = (
                         <BlockContainer2Column84 block={block}></BlockContainer2Column84>
                    )
                    break;
               case '2-Column-4/8':
                    content = (
                         <BlockContainer2Column48 block={block}></BlockContainer2Column48>
                    )
                    break;
               case '2-Column-4/8-md':
                    content = (
                         <BlockContainer2Column48Md block={block}></BlockContainer2Column48Md>
                    )
                    break;
               case '3-Column':
                    content = (
                         <BlockContainer3Column block={block}></BlockContainer3Column>
                    )
                    break;
               default:
                    content = (
                         <BlockContainer block={block}></BlockContainer>
                    )
                    break;
          }
     }

     return (
          <div className={block.containerClass}>
               {block.headerDescription && <RichText data={block.headerDescription}></RichText>}
               {content}
          </div>
     )
}

export {
     ContentfulBlockContainer,
     BlockContainerFaq
}
