import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { RichText } from '../../../richTextOptions';
import Slider from 'react-slick';


class ArticleTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeFaqIndex: '',
            currentTabIndex: 0,
            slideIndex: 0,
            firstActive: 1
        }
    }
    
    // UNSAFE_componentWillMount() {
    //     this.setState({
    //         firstActive: 1
    //     })
    // }

    setActiveFaqIndex = (index) => this.setState({ activeFaqIndex: index,  firstActive: 0})
   
    typeEnterOnTab = (e, i) => {
        if (e.keyCode === 13) {
            this.setState({ currentTabIndex: i });
        }
    }

    render() {
        const { infoArticle, isPreview } = this.props;
        const { currentTabIndex, slideIndex } = this.state;
        const settings = {
            speed: 600,
            dots: false,
            arrows: true,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            beforeChange: (current, next) => this.setState({ slideIndex: next })
        };

        return (
            <>
                <div className="is-desktop">
                {
                    (infoArticle !== null) && (<div className="tab-content-article">
                        <Slider {...settings} className="tab-title-slider">
                            {
                                infoArticle.map((tab, i) => {
                                    const title = isPreview ? tab.fields.tabTitle : tab.tabTitle;
                                    return (
                                    <div key={i}>
                                        <a
                                            tabIndex="0"
                                            className={`tab-title-selector tab-title-${i}${currentTabIndex == i ? ' active' : ''}${slideIndex == i ? ' first-tab-show' : ''}${(slideIndex + 3) == i ? ' last-tab-show' : ''}`}
                                            onClick={() => this.setState({ currentTabIndex: i })}
                                            onKeyDown={(e) => this.typeEnterOnTab(e, i)}>
                                            {title}
                                        </a>
                                    </div>)
                                })
                            }
                        </Slider>
                        {
                            infoArticle.map((tab, i) => {
                                const content = isPreview ? tab.fields.tabContent : tab.tabContent;
                                return (<div key={i} className={`tab-content ${currentTabIndex == i ? 'active' : ''}`}>
                                    { content && <RichText data={content}></RichText>}
                                </div>)
                            })
                        }
                    </div>)
                }
                </div>
                <div className="is-mobile">
                    <Accordion className="accordion-content-article" defaultActiveKey='0'>
                    {
                        (infoArticle !== null) && (
                            infoArticle.map((val, i) => {
                                const title =  isPreview ? val.fields.tabTitle : val.tabTitle;
                                const content = isPreview ? val.fields.tabContent : val.tabContent;
                                if (this.state.activeFaqIndex === i || (this.state.firstActive === 1 && i === 0)) {
                                    return (<div className="accordion-select" key={i}>
                                        <Accordion.Toggle as="div" className={"accordion-title active title-"+i} eventKey={i.toString()} onClick={() => this.setActiveFaqIndex('')}>
                                            {title}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse className="answer" eventKey={i.toString()}>
                                            { content && <RichText data={content}></RichText>}
                                        </Accordion.Collapse>
                                    </div>)
                                }
                                
                                return (<div className="accordion-select" key={i}>
                                    <Accordion.Toggle as="div" className={"accordion-title title-"+i} eventKey={i.toString()} onClick={() => this.setActiveFaqIndex(i)}>
                                        {title}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse className="answer" eventKey={i.toString()}>
                                        {content && <RichText data={content}></RichText>}
                                    </Accordion.Collapse>
                                </div>)
                            })
                        )
                    }
                    </Accordion>
                </div>
            </>
        )
    }
}

export default ArticleTab;
